<template>
    <div id="app">
        <div style="display: flex; justify-content: start; align-items: self-start;">
            <div class="q-gutter-md row justify-start q-carousel__navigation-inner row items-center" style="margin-top: 5px;">
                <p style="color: #564254; font-size: 22px; font-weight: bolder; margin-left: 50px; flex-shrink: 0;">Form 2316</p>
            </div>
            <q-form v-if="isAdmin" ref="vForm" @submit="onSubmitSearch" style="background: transparent; width: 100%;">
                <!-- <q-input v-model="searchModel" dense outlined :rules="[formRules.required]" placeholder="Search employee here...">
                    <template v-slot:append>
                    <q-icon name="fa fa-search" />
                    </template>
                </q-input> -->
                <q-select v-if="isAdmin" filled v-model="search" use-input input-debounce="0" label="Search Employee" @input="getEmployeeForm(search)" 
    			        :options="employeeList" @filter="filterFn"
    			        style="width: 550px" behavior="dialog" class="caps"
                        :rules="[ val => val && val.length > 0 || 'Please select Employee']"
    			      	>
    			        <template v-slot:no-option>
    			          <q-item>
    			            <q-item-section class="text-grey">
    			              No results
    			            </q-item-section>
    			          </q-item>
    			        </template>
    			</q-select>
            </q-form>
            <!-- <div class="row justify-end q-carousel__navigation-inner">
                <q-select v-model="location" :options="locationOptions" label="LOCATION" @input="getEmployees" style="width: 200px" tabindex="0" />
                <q-select v-model="department" :options="departments" option-value="id" option-label="name"  emit-value map-options label="DEPARTMENT" @input="getEmployees" style="width: 200px" />
            </div> -->
        </div>
    <form @submit.prevent="submitForm" style="margin: auto; margin-left: 15%;" ref="content">
        <div style="display: flex; justify-content: center; align-items: center; gap: 20px; margin-bottom: -30px;">
            <img src="../../assets/bir.png" width="60" height="60" alt="" style="margin-bottom: 15px;">
            <p style="font-weight: bold; text-align: center;">Republic of the Philippines <br> Department of Finance <br> Bureau of Internal Revenue</p>
        </div>
      <table border="1" width="100%" cellpadding="5" cellspacing="0" style="max-width: 1100px; min-width: 1100px; border-collapse: collapse; table-layout: fixed;">
        <tr>
            <td width="25%" style="text-align: center;">
                BIR Form No.<br />
                <span style="font-weight: bold; font-size: 30px;">2316</span><br />
                {{currentMonth}} {{ currentYear }}(ENCS)
            </td>
            <td width="50%" style="font-weight: bold; font-size: 20px; text-align: center;">
                <p style="width: 60%; margin: auto;">Certificate of Compensation Payment/Tax Withheld</p>
                <p  style="font-weight: normal;  font-size: 15px;">For Compensation Payment With or Without Tax Withheld</p>
            </td>
            <td>
                <img style="width: 100%; height: 80px;" src="../../assets/barcode1.png" alt="">
                <p style="margin: 0; margin-bottom: -10px; margin-top: -5px; float: right;">2316 {{ currentMonthNum }}/{{ currentDayNum }} ENCS</p>
            </td>
        </tr>
        
       <!--<tr>-->
       <!--    <td colspan="3" style="display:flex; width: 375%; justify-content: space-between; align-items: center;">-->
       <!--       <div style="display: flex; align-items: center; gap: 50px; border-right: 1px solid black; width: 50%;">-->
       <!--           <p>1. For the Year <br /> (YYYY)</p>-->
       <!--           <input type="text" name="" id="" value="" style="height: 25px" />-->
       <!--       </div>-->
       <!--       <div style="display: flex; align-items: center; gap: 50px;">-->
       <!--           <p>1. For the Year <br /> (YYYY)</p>-->
       <!--           <input type="text" name="" id="" value="" style="height: 25px" />-->
       <!--       </div>-->
       <!--    </td>-->
       <!--</tr>-->
        
    </table>
    
    <main style="max-width: 1100px; min-width: 1100px; border: 0.5px solid black; font-size: 15px; display: flex; margin-top: -20px;">
        <div>
            <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>
                    1. For the Year <br />
                    (YYYY)
                </p>
                <input v-model="form.for_the_year" type="number" min="1950" max="2099" maxlength="4" step="1" placeholder="YYYY" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
            </div>
        </div>

        <!-- Part 1: Employee Information -->
        <div style="border: 0.5px solid black; text-align: center; font-weight: bold;">
            <p style="font-size: 15px;">Part I - Employee Information</p>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>3. TIN</p>
                <input v-model="form.tin" type="text" maxlength="14" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: center; gap: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column; margin-bottom: 22px;">
                  <p>4. Employee's Name <br />(Last Name, First Name, Middle Name)</p>
                  <input v-model="form.employee_name" type="text" readonly style="height: 25px;" />
                </div>
                <div style="display: flex; justify-content: center; flex-direction: column;">
                    <p>5. RDO Code</p>
                    <input  v-model="form.rdo_code" type="text" maxlength="3" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: space-between; padding-left: 20px; padding-right: 20px; padding-bottom: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column;">
                <p>6. Registered Address</p>
                <input v-model="form.registered_address" type="text" :readonly="!isAdmin" style="height: 25px; width: 147%;" />
                </div>
                <div style="display: flex; justify-content: center; flex-direction: column;">
                    <p>6A. Zip Code</p>
                    <input v-model="form.registered_zip" type="text" maxlength="4" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: space-between; padding-left: 20px; padding-right: 20px; padding-bottom: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column;">
                <p>6B. Local Home Address</p>
                <input v-model="form.local_address" type="text" :readonly="!isAdmin" style="height: 25px; width: 147%;" />
                </div>
                <div style="display: flex; justify-content: center; flex-direction: column;">
                    <p>6C. Zip Code</p>
                    <input v-model="form.local_zip" type="text" maxlength="4" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: space-between; padding-left: 20px; padding-right: 20px; padding-bottom: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column; width: 100%;">
                <p>6D. Foreign Address</p>
                <input v-model="form.foreign_address" type="text" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: center; gap: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column; margin-bottom: 18px;">
                <p style="margin-right: 25px;">7. Date of Birth (MM/DD/YYYY)</p>
                <input v-model="form.date_of_birth" type="date" :readonly="!isAdmin" style="height: 25px;" />
                </div>
                <div style="display: flex; justify-content: center; flex-direction: column; margin-bottom: 18px;">
                    <p>8. Contact Number</p>
                    <input v-model="form.contact_number" type="text" maxlength="11" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px; width: 110%;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>9. Statutory Minimum Wage rate per day</p>
                <input v-model="form.statutory_min_wage_per_day" type="text" :readonly="!isAdmin" @keypress="isNumber($event)" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>10. Statutory Minimum Wage rate per month</p>
                <input v-model="form.statutory_min_wage_per_month" type="text" :readonly="!isAdmin" @keypress="isNumber($event)" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <!-- Missing column -->
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <input type="checkbox" name="" id="" value="" style="height: 25px;" />
                <p>11. Statutory Minimum Wage Earner (MWE) whose compensation is exempt from withholding tax and not subjec to income tax</p>
            </div>
        </div>
        <!-- Missing column -->
        
        <!-- Part 2: Present Employer Information -->
        <div style="border: 0.5px solid black; text-align: center; font-weight: bold;">
            <p>Part II - Employer Information (Present)</p>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>12. TIN</p>
                <input v-model="form.present_employer_tin" type="text" maxlength="14" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: center; gap: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column; margin-bottom: 18px; width: 90%;">
                <p>13. Employer's Name</p>
                <input v-model="form.present_employer_name" type="text" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: space-between; padding-left: 20px; padding-right: 20px; padding-bottom: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column;">
                <p>14. Registered Address</p>
                <input v-model="form.present_employer_address" type="text" :readonly="!isAdmin" style="height: 25px; width: 147%;" />
                </div>
                <div style="display: flex; justify-content: center; flex-direction: column;">
                    <p>14A. Zip Code</p>
                    <input v-model="form.present_employer_zip" type="number" maxlength="4" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 5px; align-items: center; padding-left: 20px;">
                <p>15. Type of Employer</p>
                
                <input type="checkbox" @click="form.employer_type = 'Main Employer'" name="main" id="" value="" style="height: 25px;" />
                <label for="main">Main Employer</label>
                
                <input type="checkbox" @click="form.employer_type = 'Secondary Employer'" name="main" id="" value="" style="height: 25px;" />
                <label for="main">Secondary Employer</label>
            </div>
        </div>
        
        <!-- Part 3: Previous Employer Information -->
        <div style="border: 0.5px solid black; text-align: center; font-weight: bold;">
            <p>Part III - Employer Information (Previous)</p>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>16. TIN</p>
                <input v-model="form.previous_employer_tin" type="text" maxlength="14"  @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: center; gap: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column; margin-bottom: 18px; width: 90%;">
                <p>17. Employer's Name</p>
                <input v-model="form.previous_employer_name" type="text" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; align-items: center; justify-content: space-between; padding-left: 20px; padding-right: 20px; padding-bottom: 10px">
                <div style="display: flex; justify-content: center; flex-direction: column;">
                <p>18. Registered Address</p>
                <input v-model="form.previous_employer_address" type="text" :readonly="!isAdmin" style="height: 25px; width: 147%;" />
                </div>
                <div style="display: flex; justify-content: center; flex-direction: column;">
                    <p>18A. Zip Code</p>
                    <input v-model="form.previous_employer_zip" type="text" maxlength="4" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px;" />
                </div>
            </div>
        </div>
        
        <!-- Part 4A: Compensation and Tax Details -->
        <div style="border: 0.5px solid black; text-align: center; font-weight: bold;">
            <p>Part IV-A - Summary</p>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>19. Gross Compensation Income from Present Employer (Sum of Items 38 and 52) </p>
                <input v-model="form.gross_income_from_present_employer" type="text" @keypress="isNumber($event)" readonly style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>20.<span>Less: Total Non-Taxable/Exempt Compensation</span> <br />
                    Income from present employer (From Item 38)
                </p>
                <input v-model="form.less_non_taxable_compensation" type="text" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>21. Taxable Compensation Income from Present Employer (Item 19 Less Item 20) (From Item 52) </p>
                <input v-model="form.taxable_income_from_present_employer" type="text" @keypress="isNumber($event)" readonly style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>22. Add: Taxable Compensation Income from Previous Employer, if applicable </p>
                <input  v-model="form.taxable_income_from_previous_employer" type="text" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; justify-content: space-between; align-items: center; padding-left: 20px;">
                <p>23. Gross Taxable Compensation Income <br /> (Sum of Items 21 and 22) </p>
                <input v-model="form.gross_taxable_compensation" type="text" @keypress="isNumber($event)" readonly style="height: 25px; margin-right: 20px;" />
            </div>
        </div>

        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; justify-content: space-between; align-items: center; padding-left: 20px;">
                <p>24. Tax Due </p>
                <input v-model="form.tax_due" type="text" @keypress="isNumber($event)" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <p style="height:3px; padding-left: 20px;">25. Amount of Taxes Withheld</p>
            <div style="display: flex; justify-content: center; align-items: center; padding-left: 20px; flex-direction: column;">
                <div style="height:35px; display: flex; justify-content: space-between; align-items: center; width: 100%;">
                    <p>25A. Present Employer</p>
                    <input v-model="form.present_employer_taxes_withheld" type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
                <div style="height:35px; display: flex; justify-content: space-between; align-items: center; width: 100%;">
                    <p>25B. Previous Employer, if applicable</p>
                    <input v-model="form.previous_employer_taxes_withheld" type="text" name="" id="" value="" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>26. Total Amount of Taxes Withheld as adjusted <br /> (Sum of Items 25A and 25B) </p>
                <input v-model="form.total_amount_taxes_withheld" type="text" readonly style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                <p>27. 5% Tax Credit (PERA Act of 2008) </p>
                <input v-model="form.tax_credit" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
            </div>
        </div>
        
        <div style="border: 0.5px solid black;">
            <div style="display: flex; gap: 50px; align-items: center; padding-left: 20px;">
                <p>28. Total Taxes Withheld (Sum of Items 26 and 27)  </p>
                <input v-model="form.total_taxes_withheld" type="text" readonly style="height: 25px; margin-right: 20px;" />
            </div>
        </div>    
        </div>
        
        <div>
            <div style="border: 0.5px solid black;">
                <div style="display: flex; gap: 10px; align-items: center; padding-left: 20px;">
                    <p>
                        2. For the Period <br />
                         From (MM/DD)
                    </p>
                    <input v-model="form.for_the_period_from" type="text" maxlength="5" placeholder="MM/DD" @keypress="formatFrom(form.for_the_period_from); isNumber($event)" :readonly="!isAdmin" style="height: 25px; width:20%;" />
                    <p>To (MM/DD)</p>
                    <input v-model="form.for_the_period_to" type="text" maxlength="5" placeholder="MM/DD"  @keypress="formatTo(form.for_the_period_to); isNumber($event)" :readonly="!isAdmin" style="height: 25px; width:20%;" />
                </div>
            </div>
        
            <!-- Part 4B B: Taxable Compensation Details -->
            <div style="border: 0.5px solid black; text-align: center; font-weight: bold;">
                <p style="font-size: 15px;">Part IV-B - Details of Compensation Income & Tax Withheld from Present Employer</p>
            </div>
            
            <div style="display:flex; justify-content: space-around; align-items: center;  margin-top: 30px;">
              <p>A. NON-TAXABLE/EXEMPT <br /> COMPENSATION INCOME</p>
              <p>Amount</p>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>29. Basic Salary (including the exempt P250,000 & below) <br> or the Statutory Minimum Wage of the MWE </p>
                    <input v-model="form.non_taxable_basic_salary" type="text" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>30. Holiday Pay (MWE)  </p>
                    <input type="text" v-model="form.non_taxable_holiday_pay" @keypress="isNumber($event)" :readonly="!isAdmin"  style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>31. Overtime Pay (MWE)  </p>
                    <input type="text" v-model="form.non_taxable_overtime_pay" @keypress="isNumber($event)" :readonly="!isAdmin"  style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>32. Night Shift Differential (MWE)</p>
                    <input type="text" v-model="form.non_taxable_night_shift" @keypress="isNumber($event)" :readonly="!isAdmin"  style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>33. Hazard Pay (MWE)</p>
                    <input type="text" v-model="form.non_taxable_hazard_pay" @keypress="isNumber($event)" :readonly="!isAdmin"  style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>34. 13th Month Pay and Other Benefits <br /> (Maximum of P90,000)</p>
                    <input type="text" v-model="form.non_taxable_thirteenth_month" @keypress="isNumber($event)" :readonly="!isAdmin"  style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>35. De Minimis Benefits</p>
                    <input type="text" v-model="form.non_taxable_de_minimis" :readonly="!isAdmin"  style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>36. SSS, GSIS, PHIC, & PAG-IBIG Contributions and Union Dues (Employee share only)</p>
                    <input type="text" v-model="form.non_taxable_government_contributions" :readonly="!isAdmin"  style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>37. Salaries and Other Forms of Compensation</p>
                    <input type="text" v-model="form.non_taxable_salaries_others" :readonly="!isAdmin"  style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>38. Total Non-Taxable/Exempt Compensation</p>
                    <input type="text" v-model="form.total_non_taxable_compensation" :readonly="!isAdmin"  style="height: 25px; margin-right: 20px;" />
                </div>
            </div> 
            
            <div>
                <div style="padding-left: 20px; margin-top: 50px;">
                    <p>B. TAXABLE COMPENSATION INCOME REGULAR</p>
                </div>
            </div> 

            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>39. Basic Salary</p>
                    <input v-model="form.taxable_basic_salary" type="text" :readonly="!isAdmin"  style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>40. Representation</p>
                    <input  v-model="form.representation" type="text" :readonly="!isAdmin"  style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>41. Transportation</p>
                    <input v-model="form.transportation" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px; margin-right: 20px;">
                    <p>42. Cost of Living Allowance (COLA)</p>
                    <input v-model="form.cost_of_living_allowance" @keypress="isNumber($event)" type="text" :readonly="!isAdmin" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>43. Fixed Housing Allowance</p>
                    <input v-model="form.fixed_housing_allowance" @keypress="isNumber($event)" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <p style="height:3px; padding-left: 20px;">44. Others (Specify)</p>
                <div style="display: flex; justify-content: center; align-items: center; padding-left: 20px; flex-direction: column;">
                    <div style="height:35px; display: flex; justify-content: flex-start; align-items: center; width: 100%;">
                        <p>44A</p>
                        <input v-model="form.others_1" type="text" :readonly="!isAdmin" style="height: 25px; margin-left: 20px;" />
                    </div>
                    <div style="height:35px; display: flex; justify-content: flex-start; align-items: center; width: 100%;">
                        <p>44B</p>
                        <input v-model="form.others_2" type="text" :readonly="!isAdmin" style="height: 25px; margin-left: 20px;" />
                    </div>
                </div>
            </div>
            
            <div>
                <div style="padding-left: 20px; margin-top: 50px;">
                    <p>Supplementary</p>
                </div>
            </div> 
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>45. Commission</p>
                    <input v-model="form.commission" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>46. Profit Sharing</p>
                    <input v-model="form.profit_sharing" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>47. Fees Including Director's Fees</p>
                    <input v-model="form.fees_and_director_fees" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>48. Taxable 13th Month Benefits</p>
                    <input v-model="form.taxable_13th_month" @keypress="isNumber($event)" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>49. Hazard Pay</p>
                    <input v-model="form.taxable_hazard_pay" @keypress="isNumber($event)" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>50. Overtime Pay</p>
                    <input v-model="form.taxable_overtime_pay" @keypress="isNumber($event)" type="text" :readonly="!isAdmin" style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
            <div>
                <p style="height:3px; padding-left: 20px;">51. Others (Specify)</p>
                <div style="display: flex; justify-content: center; align-items: center; padding-left: 20px; flex-direction: column;">
                    <div style="height:35px; display: flex; justify-content: flex-start; align-items: center; width: 100%;">
                        <p>51A</p>
                        <input v-model="form.others_3" type="text" :readonly="!isAdmin" style="height: 25px; margin-left: 20px;" />
                    </div>
                    <div style="height:35px; display: flex; justify-content: flex-start; align-items: center; width: 100%;">
                        <p>51B</p>
                        <input v-model="form.others_4" type="text" :readonly="!isAdmin" style="height: 25px; margin-left: 20px;" />
                    </div>
                </div>
            </div>
            
            <div>
                <div style="display: flex; justify-content: space-between; align-items: center; padding-left: 20px;">
                    <p>52. Total Taxable Compensation Income <br /> (Sum of Items 39 to 51B)</p>
                    <input v-model="form.total_taxable_income" readonly style="height: 25px; margin-right: 20px;" />
                </div>
            </div>
            
        </div>
    </main>

    <section style="max-width: 1100px; min-width: 1100px; border: 0.5px solid black; font-size: 12px; padding-left: 5px; padding-right: 5px;">
            <p style="font-weight: 500; margin-bottom: 0px;">
                I/We declare, under the penalties of perjury that this certificate has been made in good faith, verified by me/us, and to the best of my/our knowledge and belief, 
                is true and correct, pursuant to the provisions of the National Internal Revenue Code, as amended, and the regulations issued under authority thereof. Further, 
                I/we give my/our consent to the processing of my/our information as contemplated under the *Data Privacy Act of 2012 (R.A. No. 10173) for legitimate and lawful purposes.
            </p>
    </section>

    <section style="max-width: 1100px; min-width: 1100px; border: 0.5px solid black; font-size: 15px; padding-left: 10px; padding-right: 10px; display: flex; gap: 10px;">
        <div style="width: 50%;">
            <div>
                <div style="display: flex; align-items: end; margin-bottom: -10px; margin-top: 10px;">
                    <p>53.</p>
                    <div>
                        <p style="text-align: center; margin-top: 10px; margin-bottom: -7px; font-size: 12px;">{{ form.employer_name }}</p>
                        <hr style="width: 470px; text-align: center; margin-left: 20px;">
                    </div>
                </div>
                <p style="text-align: center;"> Present Employer/Authorized Agent Signature over Printed Name</p>
            </div>

            <div>
                <div style="display: flex; align-items: end; margin-bottom: -10px; margin-top: 10px;">
                    <p>54.</p>
                    <div>
                        <p style="text-align: center; margin-top: 10px; margin-bottom: -7px; font-size: 12px;">{{ cleanName(form.employee_name) }}</p>
                        <hr style="width: 470px; text-align: center; margin-left: 20px;">
                    </div>
                </div>
                <p style="text-align: center;">Employee Signature over Printed Name</p>
            </div>

            <!-- <div style="margin-bottom: -20px;">
                <div style="display: flex; align-items: end; margin-bottom: -10px;">
                    <p>54.</p>
                    <hr style="width: 470px; text-align: center;">
                </div>
                <p style="text-align: center;">Employee Signature over Printed Name</p>
            </div> -->

            <div style="display: flex; margin-top: -15px;">
                <div style="display: flex; align-items: center; margin-top: 10px;">
                    <p style="margin-top: auto; margin-bottom: auto;">CTC/Valid ID No. of Employee</p>
                    <input type="text" style="width: 60%; margin-right: 30px;">
                </div>
                <div style="display: flex; align-items: center; margin-top: 10px;">
                    <p style="margin-top: auto; margin-bottom: auto;">Place of Issue</p>
                    <input type="text" style="width: 100%;">
                </div>
            </div>
        </div>

        <div style="width: 50%;">
            <div style="display: flex; flex-direction: column;">
                <div style="display: flex; align-items: center; margin-top: 10px;">
                    <p style="margin-top: auto; margin-bottom: auto; margin-right: 30px;">Date Signed</p>
                    <input type="date" style="width: 60%;">
                </div>

                <div style="display: flex; align-items: center; margin-top: 40px;">
                    <p style="margin-top: auto; margin-bottom: auto; margin-right: 30px;">Date Signed</p>
                    <input type="date" style="width: 60%;">
                </div>

                <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px; width: 100%;">
                    <div style="display: flex; width: 70%;">
                        <p style="margin-top: auto; margin-bottom: auto; margin-right: 30px;">Date Signed</p>
                        <input type="date" style="width: 60%;">
                    </div>

                    <div style="width: 30%; margin-bottom: 12px;">
                        <p style="width: 100%; margin-top: -10px; margin-bottom: 0px;">Amount paid, if CTC</p>
                        <input type="text" style="width: 90%;">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section style="max-width: 1100px; min-width: 1100px; border: 0.5px solid black; font-size: 14px; padding-left: 5px; padding-right: 5px;">
        <p style="font-weight: bold; text-align: center; margin-bottom: 0px;">
            To be accomplished under substituted filing
        </p>
    </section>

    <section style="max-width: 1100px; min-width: 1100px; border: 0.5px solid black; font-size: 15px; padding-left: 10px; padding-right: 10px; display: flex; gap: 10px;">
        <div style="width: 50%; display: flex; flex-direction: column; justify-content: space-between;">
            <p style="text-align: center;">I declare, under the penalties of perjury that the information herein stated are reported under BIR Form No. 1604-C which has been filed with the Bureau of <br> Internal Revenue.</p>
            <div>
                <div style="display: flex; align-items: end; margin-bottom: -10px; margin-top: 10px;">
                    <p>55.</p>
                    <div>
                        <p style="text-align: center; margin-top: 10px; margin-bottom: -7px; font-size: 12px;">{{ form.employer_name }}</p>
                        <hr style="width: 470px; text-align: center; margin-left: 20px;">
                    </div>
                </div>
                <p style="text-align: center; margin-left: 10px;">Present Employer/Authorized Agent Signature over Printed Name <br> (Head of Accounting/Human Resource or Authorized Representative)</p>
            </div>
        </div>

        <div style="width: 50%; display: flex; flex-direction: column; justify-content: space-between;">
            <p style="text-align: center; font-size: 12px;">
                I declare, under the penalties of perjury that I am qualified under substituted filing of Income Tax Return
                (BIR Form No. 1700), since I received purely compensation income from only one employer in the Philippines
                for the calendar year; that taxes have been correctly withheld by my employer (tax due equals tax withheld); that
                the BIR Form No. 1604-C filed by my employer to the BIR shall constitute as my income tax return; and that BIR
                Form No. 2316 shall serve the same purpose as if BIR Form No. 1700 has been filed pursuant to the provisions
                of Revenue Regulations (RR) No. 3-2002, as amended.
            </p>
            <!-- <div>
                <div style="display: flex; align-items: end; margin-bottom: -10px;">
                    <p>56.</p>
                    <hr style="width: 470px; text-align: center;">
                </div>
                <p style="text-align: center; margin-left: 10px;">Employee Signature over Printed Name</p>
            </div> -->
            <div>
                <div style="display: flex; align-items: end; margin-bottom: -10px; margin-top: 10px;">
                    <p>56.</p>
                    <div>
                        <p style="text-align: center; margin-top: 10px; margin-bottom: -7px; font-size: 12px;">{{ form.employee_name }}</p>
                        <hr style="width: 470px; text-align: center; margin-left: 20px;">
                    </div>
                </div>
                <p style="text-align: center; margin-left: 10px;">Employee Signature over Printed Name</p>
            </div>
        </div>
    </section>
    </form>
    <div style="margin: auto; text-align: center; width: 80%; margin-top: 20px;" v-if="isAdmin">
        <q-btn push color="primary" label="Save" style="width: 30%;" @click="submitForm()"/>
        <q-btn push color="red" label="Print" style="width: 30%;" @click="download()"/>
      </div>
  </div>
  </template>
  
<script>
import axios from "axios";
import { readonly } from "vue";
import vueCookies from 'vue-cookies';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas"
import { ref } from 'vue'
import { parse } from "postcss";
// import { required, numeric } from '@vuelidate/validators'
// import useVuelidate from '@vuelidate/core'

export default {
    data() {
      return {
        isAdmin: false,
        form: {
            employee_id: null,
            for_the_year: 2024,
            for_the_period_from: "",
            for_the_period_to: "",
            tin: "",
            employee_name: "",
            rdo_code: "",
            registered_address: "",
            registered_zip: "",
            local_address: "",
            local_zip: "",
            foreign_address: "",
            date_of_birth: "",
            contact_number: "",
            statutory_min_wage_per_day: "",
            statutory_min_wage_per_month: "",
            present_employer_tin: "",
            present_employer_name: "",
            present_employer_address: "",
            present_employer_zip: "",
            employer_type: "",
            previous_employer_tin: "",
            previous_employer_name: "",
            previous_employer_address: "",
            previous_employer_zip: "",
            gross_income_from_present_employer: "",
            less_non_taxable_compensation: "",
            // income_from_present_employer: "",
            taxable_income_from_present_employer: "",
            taxable_income_from_previous_employer: "",
            tax_due: "",
            present_employer_taxes_withheld: "",
            previous_employer_taxes_withheld: "",
            total_amount_taxes_withheld: "",
            tax_credit: "",
            total_taxes_withheld: "",
            non_taxable_basic_salary: "",
            statutory_min_wage_earner: "", 
            gross_taxable_compensation: "",
            // non_taxable_total_taxes: "",
            non_taxable_holiday_pay: "",
            non_taxable_overtime_pay: "",
            non_taxable_night_shift: "",
            non_taxable_hazard_pay: "",
            non_taxable_thirteenth_month: "",
            non_taxable_de_minimis: "",
            non_taxable_government_contributions: "",
            non_taxable_salaries_others: "",
            non_taxable_exempt_compensation: "",
            // holiday_pay: "",
            // overtime_pay: "",
            // night_shift_diff: "",
            // hazard_pay: "",
            // thirteenth_month_pay: "",
            // de_minimis_benefits: "",
            // gov_contributions: "",
            // salaries_and_others: "",
            total_non_taxable_compensation: "",
            taxable_basic_salary: "",
            representation: "",
            transportation: "",
            cost_of_living_allowance: "",
            fixed_housing_allowance: "",
            others_1: "",
            others_2: "",
            commission: "",
            profit_sharing: "",
            fees_and_director_fees: "",
            taxable_13th_month: "",
            taxable_hazard_pay: "",
            taxable_overtime_pay: "",
            others_3: "",
            others_4: "",
            total_taxable_income: "",
            employer_name: "",
        },
        currentMonth: null,
        currentYear: null,
        searchModel: null,
        currentMonthNum: null,
        currentDayNum: null,
        search: ref(null),
        employeeOptions:[],
        employeeList: this.employeeOptions,
      };
    },

    // validations() {
    //     return {
    //         form: {
    //             others_2: { required },
    //             commission: { required },
    //             profit_sharing: { required },
    //             fees_and_director_fees: { required },
    //             taxable_13th_month: { required, numeric }
    //         }
    //     };
    // },
    created() {
        this.getListOfEmployees();
    },

    mounted: function() {
      this.getUserData()
      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      const d = new Date();
      this.currentMonth = month[d.getMonth()]
      this.currentYear = d.getFullYear()
      this.currentMonthNum = d.getMonth() + 1
      this.currentDayNum = d.getDate()
      console.log(vueCookies.get('sgcchris'));
    },

    methods: {
      submitForm() {
        let formData = new FormData();
        for (const key in this.form) {
            formData.append(key, this.form[key]); // Access value with key
            // console.log(key, this.form[key]); // Log key and value
        }
        axios.create({
            baseURL: this.apiUrl,
            headers: { 'Authorization': `Bearer ${this.token}` }
        }).post('/create/form/2316', formData)
        .then((res) => {
            this.$q.notify({
              progress: true,
              message: `Form submitted successfully.`,
              color: 'green'
            }, 2000)
        }).catch((error) => {
          this.$q.notify({
              progress: true,
              message: `Something went wrong.`,
              color: 'red'
          }, 2000)
            console.log(error)
        });
      },

      isNumber: function(evt) {
          evt = (evt) ? evt : window.event;
          var charCode = (evt.which) ? evt.which : evt.keyCode;
          if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
              evt.preventDefault();
          } else {
              return true;
          }
      } ,

      formatFrom(input) {
          let value = input.replace(/[^\d]/g, "");
          if (value.length > 2) {
              value = value.slice(0, 2) + "/" + value.slice(2, 4);
          }
          this.form.for_the_period_from = value
          console.log(value)
      },

      formatTo(input) {
          let value = input.replace(/[^\d]/g, "");
          if (value.length > 2) {
              value = value.slice(0, 2) + "/" + value.slice(2, 4);
          }
          this.form.for_the_period_to = value
          console.log(value)
      },

      getUserData(){
        if(vueCookies.get('sgcchris').user.role_id === 1){
          this.isAdmin = true;
        }else{
          this.getEmployeeForm()
        }
      },

      getEmployeeForm(id){
        // clear form
        Object.keys(this.form).forEach(key => {
            this.form[key] = null;
        });

        let formData = new FormData();
        if(id){
            const match = id.match(/^([\w\d]+)\s*\|/);
            const employeeId = match ? match[1] : null;
            formData.append("employee_id", employeeId);
        }else{
            formData.append("employee_id", vueCookies.get('sgcchris').user.id);
        }

        axios.create({
            baseURL: this.apiUrl,
            headers: { 'Authorization': `Bearer ${this.token}` }
        }).post('/search/form-2316', formData)
        .then((res) => {
          if (res.data && res.data.data !== null) {
            Object.assign(this.form, res.data.data);

            this.form.gross_income_from_present_employer = this.preventNan(this.form.total_non_taxable_compensation) + this.preventNan(this.form.total_taxable_income);
            this.form.gross_taxable_compensation = this.preventNan(this.form.taxable_income_from_present_employer) + this.preventNan(this.form.taxable_income_from_previous_employer);
            this.form.total_amount_taxes_withheld = this.preventNan(this.form.present_employer_taxes_withheld) + this.preventNan(this.form.previous_employer_taxes_withheld);
            this.form.total_taxes_withheld = this.preventNan(this.form.total_amount_taxes_withheld) + this.preventNan(this.form.tax_credit);
            this.form.taxable_income_from_present_employer = this.preventNan(this.form.gross_income_from_present_employer) - this.preventNan(this.form.less_non_taxable_compensation);
            this.form.total_taxable_income = this.preventNan(this.form.taxable_basic_salary)
                + this.preventNan(this.form.representation)
                + this.preventNan(this.form.transportation)
                + this.preventNan(this.form.cost_of_living_allowance)
                + this.preventNan(this.form.fixed_housing_allowance)
                + this.preventNan(this.form.others_1)
                + this.preventNan(this.form.others_2)
                + this.preventNan(this.form.commission)
                + this.preventNan(this.form.profit_sharing)
                + this.preventNan(this.form.fees_and_director_fees)
                + this.preventNan(this.form.taxable_13th_month)
                + this.preventNan(this.form.taxable_hazard_pay)
                + this.preventNan(this.form.taxable_overtime_pay)
                + this.preventNan(this.form.others_3)
                + this.preventNan(this.form.others_4);

            this.formattedNumber();

            this.$q.notify({
              progress: true,
              message: `Get employee form 2316 success.`,
              color: 'green'
            }, 2000)
          }

          if(res.data.data === null){
            this.$q.notify({
              progress: true,
              message: `No data found for this employee.`,
              color: 'red'
            }, 2000)
          }
        }).catch((error) => {
          this.$q.notify({
              progress: true,
              message: `Something went wrong.`,
              color: 'red'
          }, 2000)
            console.log(error)
        });
      },

      onSubmitSearch: function() {
        this.getEmployeeForm(this.searchModel)
      },

      download() {
        const content = this.$refs.content;
        const originalOverflow = content.style.overflow;
        const originalHeight = content.style.height;
        content.style.overflow = 'visible';
        content.style.height = 'auto';

        html2canvas(content).then((canvas) => {
            const img = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('form-2316.pdf');
            // view pdf
            // const pdfBlob = pdf.output('blob');
            // const url = URL.createObjectURL(pdfBlob);
            // window.open(url);

            content.style.overflow = originalOverflow;
            content.style.height = originalHeight;
        });
      },

      filterFn (val, update) {
	     	if (val === '') {
		        update(() => {
		          this.employeeList = this.employeeOptions
                //   console.log(this.search)
		        })
	        	return
	      	}
	      update(() => {
	        	const needle = val.toLowerCase()
	        	this.employeeList = this.employeeOptions.filter(emp => emp.toLowerCase().indexOf(needle) > -1)
	      })
	  },

      getListOfEmployees: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/employee/list`)
			.then((res) => {
				this.employeeOptions = res.data.data;
			})
	  },

      // Remove all commas
      cleanName(name) {
        if(name !== null){
            return name.replace(/,/g, ''); 
        }
      },

      // Add commas to monetary values
      formattedNumber() {
        this.form.statutory_min_wage_earner =  Intl.NumberFormat().format(this.form.statutory_min_wage_earner);
        this.form.statutory_min_wage_per_month =  Intl.NumberFormat().format(this.form.statutory_min_wage_per_month);
        this.form.gross_taxable_compensation =  Intl.NumberFormat().format(this.form.gross_taxable_compensation);
        this.form.non_taxable_total_taxes =  Intl.NumberFormat().format(this.form.non_taxable_total_taxes);
        this.form.total_non_taxable_compensation =  Intl.NumberFormat().format(this.form.non_taxable_basic_salary * 12);
        this.form.total_taxable_income =  Intl.NumberFormat().format(this.form.total_taxable_income);
        this.form.taxable_income_from_present_employer =  Intl.NumberFormat().format(this.form.taxable_income_from_present_employer);
        this.form.taxable_income_from_previous_employer =  Intl.NumberFormat().format(this.form.taxable_income_from_previous_employer);
        this.form.tax_due =  Intl.NumberFormat().format(this.form.tax_due);
        this.form.present_employer_taxes_withheld =  Intl.NumberFormat().format(this.form.present_employer_taxes_withheld);
        this.form.previous_employer_taxes_withheld =  Intl.NumberFormat().format(this.form.previous_employer_taxes_withheld);
        this.form.total_amount_taxes_withheld =  Intl.NumberFormat().format(this.form.total_amount_taxes_withheld);
        this.form.tax_credit =  Intl.NumberFormat().format(this.form.tax_credit);
        this.form.total_taxes_withheld =  Intl.NumberFormat().format(this.form.total_taxes_withheld);

        let formData = new FormData();
        formData.append('empID', this.form.employee_id);
        formData.append('year', this.form.for_the_year);
        
        axios.create({
            baseURL: this.apiUrl,
            headers: { 'Authorization': `Bearer ${this.token}` }
        }).post('/backend/alphalist', formData).then((res) => {
            this.form.non_taxable_government_contributions = res.data.data.yearlyContribution;
            this.form.non_taxable_government_contributions =  Intl.NumberFormat().format(this.form.non_taxable_government_contributions);
        }).catch((error) => {
            console.log(error)
        });

        this.form.non_taxable_basic_salary =  Intl.NumberFormat().format(this.form.non_taxable_basic_salary);
        this.form.non_taxable_holiday_pay =  Intl.NumberFormat().format(this.form.non_taxable_holiday_pay);
        this.form.non_taxable_overtime_pay =  Intl.NumberFormat().format(this.form.non_taxable_overtime_pay);
        this.form.non_taxable_night_shift =  Intl.NumberFormat().format(this.form.non_taxable_night_shift);
        this.form.non_taxable_hazard_pay =  Intl.NumberFormat().format(this.form.non_taxable_hazard_pay);
        this.form.non_taxable_de_minimis =  Intl.NumberFormat().format(this.form.non_taxable_de_minimis);
        this.form.non_taxable_government_contributions =  Intl.NumberFormat().format(this.form.non_taxable_government_contributions);
        this.form.non_taxable_salaries_others =  Intl.NumberFormat().format(this.form.non_taxable_salaries_others);
        this.form.taxable_basic_salary =  this.form.total_non_taxable_compensation;
        this.form.representation =  Intl.NumberFormat().format(this.form.representation);
        this.form.transportation =  Intl.NumberFormat().format(this.form.transportation);
        this.form.cost_of_living_allowance =  Intl.NumberFormat().format(this.form.cost_of_living_allowance);
        this.form.fixed_housing_allowance =  Intl.NumberFormat().format(this.form.fixed_housing_allowance);
        this.form.others_1 =  Intl.NumberFormat().format(this.form.others_1);
        this.form.others_2 =  Intl.NumberFormat().format(this.form.others_2);
        this.form.commission =  Intl.NumberFormat().format(this.form.commission);
        this.form.profit_sharing =  Intl.NumberFormat().format(this.form.profit_sharing);
        this.form.fees_and_director_fees =  Intl.NumberFormat().format(this.form.fees_and_director_fees);
        this.form.taxable_13th_month =  Intl.NumberFormat().format(this.form.taxable_13th_month);
        this.form.taxable_hazard_pay =  Intl.NumberFormat().format(this.form.taxable_hazard_pay);
        this.form.taxable_overtime_pay =  Intl.NumberFormat().format(this.form.taxable_overtime_pay);
        this.form.others_3 =  Intl.NumberFormat().format(this.form.others_3);
        this.form.others_4 =  Intl.NumberFormat().format(this.form.others_4);
        this.form.gross_income_from_present_employer =  Intl.NumberFormat().format(this.form.gross_income_from_present_employer);
        this.form.less_non_taxable_compensation =  Intl.NumberFormat().format(this.form.less_non_taxable_compensation);
        this.form.non_taxable_thirteenth_month =  Intl.NumberFormat().format(this.form.non_taxable_thirteenth_month);
      },

      // Prevent NaN values when computing
      preventNan(val) {
        val = +val || 0
        return parseInt(val);
      },

    },
  };
</script>
  
<style scoped>
  form {
    width: auto;
    padding: 10px;
    background: white;
    border-radius: 25px;
    display: inline-block;
    margin-left: 25%;
    margin-right: 25%;
    
  }
  table {
    margin: 20px 0;
    border-collapse: collapse;
    width: 100%;
  }
  th {
    background-color: #f2f2f2;
  }
  td {
    padding: 8px;
  }
</style>
  