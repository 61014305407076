<template>
  <div class="login-container">
    <div class="container">
  <div class="header">
    <img style="display: flex; justify-content: center; align-items: center; width:400px; height: auto" src="../assets/sgcc-black.png" alt="BEAPAYDAY">
    <h2 class="title">Sign in to your account</h2>
  </div>

  <div class="form-container">
     <q-form class="form" ref="vForm" @submit="onSubmit">

      <div>
        <label for="email" class="label">Username or Email</label>
        <div class="input-container">
          <input id="email" name="email" type="text" autocomplete="email" required class="input-field" :disable="isSubmitted" v-model="login" lazy-rules :rules="[formRules.required]" />
        </div>
      </div>

      <div>
        <div class="password-label">
          <label for="password" class="label">Password</label>
        </div>
        <div class="input-container">
          <input id="password" name="password" type="password" autocomplete="current-password" required class="input-field" :disable="isSubmitted" v-model="password" lazy-rules :rules="[formRules.required]" />
        </div>

        <p class="error-text" style="text-align: center;" v-if="isInvalid">*Invalid Credentials</p>
      </div>

      <div class="submit-button-container">
        <q-btn rounded class="submit-button" label="Log in" :loading="isSubmitted" type="submit" />
      </div>
    </q-form>

    <p class="footer-text">
      Forgot Password? 
      <a class="footer-link" @click="prompt = true" style="cursor: pointer">Change Password</a>
    </p>
    </div>
    <div class="q-pa-md q-gutter-sm play-store-box flex flex-center" style="margin-top: 20px;">
      <a href="#" @click="apple = true">
        <img src="../assets/appstore.png" alt="Download on the AppStore" height="40">
      </a>
      <a href="https://play.google.com" target="_blank">
        <img src="../assets/play_store.png" alt="Get on Google Play" height="40">
      </a>
    </div>
  </div>

    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">Enter your employee ID</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="employeeid" autofocus label="Employee ID"/>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Cancel" v-close-popup />
          <q-btn flat label="Submit" @click="forgotPassword"/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="apple" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">How to download iOS app</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
            <q-list padding dense class="rounded-borders">
               <q-item clickable v-ripple>
                 <q-item-section class="text-red">Request a redemption code for IT personnel.</q-item-section>
               </q-item>
               <q-item clickable v-ripple>
                 <q-item-section>1. Open your App Store application.</q-item-section>
               </q-item>
               <q-item clickable v-ripple>
                 <q-item-section>2. Click your Account.</q-item-section>
               </q-item>
               <q-item clickable v-ripple>
                 <q-item-section>3. Click "Redeem Gift Card or Code".</q-item-section>
               </q-item>
               <q-item clickable v-ripple>
                 <q-item-section>4. Click "Enter Code Manually".</q-item-section>
               </q-item>
               <q-item clickable v-ripple>
                 <q-item-section>5. Enter CODE and click "Redeem" button.</q-item-section>
               </q-item>
            </q-list>
        </q-card-section>
        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Close" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import vueCookies from 'vue-cookies';
export default {
  name: "Login",
  data: () => ({
    login: null,
    password: null,
    isSubmitted: false,
    isInvalid: false,

    prompt: false,
    apple: false,
    employeeid:''
  }),
  methods: {

    onSubmit: function() {
      let formData = new FormData();
      formData.append("login", this.login.trim());
      formData.append("password", this.password.trim());
      this.isSubmitted = true;
      this.isInvalid = false;
      axios.post(this.apiUrl + "/login", formData)
        .then((res) => {
          if (res.data.status) {
            vueCookies.set(this.cookieKey, {
              'user': res.data.data,
              'token': res.data.token
            });
            setTimeout(() => {
              if(res.data.is_admin || res.data.is_superadmin){
                this.$router.push('/employee-management/dashboard')
              }else{
                this.$router.push('/employee/attendance')
              }        
            }, 500)
          } else {
            this.isInvalid = true;
            this.isSubmitted = false;
          }
        });
    },

    forgotPassword: function() {
        const dismiss = this.$q.notify({
              spinner: true,
              message: 'Please wait...',
              timeout: 0,
              color: 'green',
              position:'center',
        })
        if(this.employeeid.trim() != ''){
            let formData = new FormData();
            formData.append("empID", this.employeeid.trim());

            axios.post(this.apiUrl + "/mobile/forgot/password", formData).then((res) => {
              if (res.data.status) {
                  dismiss(),
                  this.prompt = false,
                  this.$q.notify({
                    message: res.data.message,
                    color: 'green',
                    position:'center',
                    timeout: 3000,
                  })

              } else {
                  dismiss(),
                  this.$q.notify({
                      message: res.data.message,
                      color: 'red',
                      position:'center',
                       timeout: 1000
                  })
              }
            });

        }else{
            dismiss(),
            this.$q.notify({
                message: 'Employee ID is required!',
                color: 'red',
                position:'bottom',
                 timeout: 1000
            })

        }
    }
  }
};
</script>

<style scoped> 
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.login-container {
  font-family: Arial, sans-serif;
  /* background-color: #f9fafb; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.container {
  width: 100%;
  max-width: 450px;
  padding: 1rem;
  border-radius: 8px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.logo {
  width: auto;
  height: 12rem;
  display: block;
  margin: 0 auto;

  border: 2px solid black;
}

.title {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;
}

.form-container {
  margin-top: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 0.875rem;
  font-weight: bold;
  color: #1f2937;
}

.input-container {
  margin-bottom: 1.5rem;
}

.input-field {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  background-color: #2b2a33;
  color: white;
  font-size: 1rem;
  outline: none;
}

.input-field:focus {
  border-color: #6366f1;
}

.password-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-message {
  text-align: center;
}

.error-text {
  color: #f87171;
}

.submit-button-container {
  margin-top: 2rem;
}

.submit-button {
  height: 40px;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  background-color: #4f46e5;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submit-button:hover {
  background-color: #4338ca;
}

.footer-text {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 0.875rem;
  color: #6b7280;
}

.footer-link {
  color: #6366f1;
  font-weight: 600;
  text-decoration: none;
}

.footer-link:hover {
  color: #4f46e5;
}

</style>

