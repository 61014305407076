<template>
	<div class="q-gutter-sm q-pa-lg" style="background-color: #f1f2f7;">

		<q-form ref="vForm" @submit="onSubmit">
			<div class="row">
				<div class="col-12 q-mb-md">
					<h4 class="q-ma-none">Create Employee</h4>
				</div>
			</div>

			<div class="row" style="background-color: white; padding: 30px; border-radius: 25px;">
				<div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
					<div class="text-h6">Personal Information</div>
					

					<div>
						<q-input standard v-model="personalInfo.employee_no" label="EMPLOYEE NO." :rules="[formRules.required]" readonly/>
						<!-- <q-btn color="primary" label="Customize Employee No." @click="prompt = true;" /> -->
					</div>
					<q-input standard v-model="personalInfo.lastname" label="LASTNAME" :rules="[formRules.required]" :label-color="validateInput(personalInfo.lastname)" class="caps"/>
					<q-input standard v-model="personalInfo.firstname" label="FIRSTNAME"  :rules="[formRules.required]" :label-color="validateInput(personalInfo.firstname)" class="caps"/>
					<q-input standard v-model="personalInfo.middlename" label="MIDDLENAME"  :label-color="validateInput(personalInfo.middlename)" class="caps"/>
					DATE OF BIRTH
					<div class="q-gutter-md row items-start">
						<q-select standard  v-model="bdayMonth" :options="bdayMonths"  option-value="id" option-label="name" use-input hide-selected fill-input input-debounce="0" label="MONTH" style="width: 110px"  :rules="[formRules.required]" emit-value map-options :label-color="validateInput(bdayMonth)">
							<template v-slot:no-option>
								<q-item>
									<q-item-section class="text-grey">
										No results
									</q-item-section>
								</q-item>
							</template>
						</q-select>
						<q-select standard  v-model="bdayDay" :options="bdayDaysOptions" use-input hide-selected fill-input input-debounce="0" label="DAY" style="width: 102px" :rules="[formRules.required]"  @filter="filterDays" :label-color="validateInput(bdayDay)">
							<template v-slot:no-option>
								<q-item>
									<q-item-section class="text-grey">
										No results
									</q-item-section>
								</q-item>
							</template>
						</q-select>
						<q-select standard  v-model="bdayYear" :options="bdayYearsOptions" use-input hide-selected fill-input input-debounce="0" label="YEAR" style="width: 105px" :rules="[formRules.required]"  @filter="filterYears" :label-color="validateInput(bdayYear)">
							<template v-slot:no-option>
								<q-item>
									<q-item-section class="text-grey">
										No results
									</q-item-section>
								</q-item>
							</template>
						</q-select>
					</div>

					<div class="q-gutter-md">
						<q-select standard v-model="personalInfo.gender" :options="gender" label="GENDER" :label-color="validateInput(personalInfo.gender)"/>
						<q-select standard v-model="personalInfo.civilStat" :options="civilStatus" label="CIVIL STATUS" :label-color="validateInput(personalInfo.civilStat)"/>
						<q-input standard v-model="personalInfo.contactNo" label="CONTACT NO." class="caps"  :label-color="validateInput(personalInfo.contactNo)"/>
						<q-input standard v-model="personalInfo.landline" label="LANDLINE" class="caps" :label-color="validateInput(personalInfo.landline)"/>
						<q-input standard v-model="personalInfo.permanentAdd" label="PERMANENT ADDRESS" class="caps" :label-color="validateInput(personalInfo.permanentAdd)"/>
						<q-input standard v-model="personalInfo.presentAdd" label="PRESENT ADDRESS" class="caps" :label-color="validateInput(personalInfo.presentAdd)"/>
						<q-input standard v-model="personalInfo.email" label="EMAIL ADDRESS" :label-color="validateInput(personalInfo.email)"/>
						<br/>
						<div class="text-h6">Person to Notify in case of Emergency</div>
						
						<q-input standard v-model="notifyPerson.personToNotif" label="PERSON TO NOTIFY" class="caps" :label-color="validateInput(notifyPerson.personToNotif)"/>
						<q-input standard v-model="notifyPerson.personToNotifContact" label="PERSON TO NOTIFY CONTACT" class="caps" :label-color="validateInput(notifyPerson.personToNotifContact)"/>
						<q-select standard v-model="notifyPerson.personToNotifRelationship" :options="relationship" label="RELATIONSHIP(Person to Notify)" :label-color="validateInput(notifyPerson.personToNotifRelationship)"/>
						<q-input standard v-model="notifyPerson.personToNotifEmailAdd" label="EMAIL ADDRESS(Person to Notify)" :label-color="validateInput(notifyPerson.personToNotifEmailAdd)"/>
					</div>
				</div>
				<div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl q-gutter-md">
					<div class="text-h6">Company Information</div>
					<q-select v-show="userData.role_id === 0" standard v-model="companyInfo.company" option-value="id" option-label="name" :options="companyOptions" label="COMPANY" emit-value map-options :label-color="validateInput(companyInfo.company)" @input="generateNewEmployeeID(companyInfo.company)"/>
					<q-select standard v-model="companyInfo.roleID" option-value="id" option-label="role" :options="roleOptions" label="ACCOUNT ROLE" emit-value map-options @input="setRole(companyInfo.role)"/>
					<q-select standard v-model="companyInfo.department" option-value="id" option-label="name"  @input="checkDepartment" :options="departmentOptions" label="DEPARTMENT" emit-value map-options :label-color="validateInput(companyInfo.department)"/>
					<q-input  standard v-model="companyInfo.dateHired" mask="date" :rules="['date']"  label="DATE HIRED" :label-color="validateInput(companyInfo.dateHired)">
						<template v-slot:append>
							<i class="far fa-calendar-alt cursor-pointer" >
								<q-popup-proxy ref="dhDateProxy" transition-show="scale" transition-hide="scale">
									<q-date v-model="companyInfo.dateHired" @input="() => $refs.dhDateProxy.hide()" />
									</q-popup-proxy>
								</i>
							</template>
						</q-input>
						<q-select standard v-model="companyInfo.position"  :options="positions" label="POSITION" :label-color="validateInput(companyInfo.position)"/>
						<q-select standard v-model="companyInfo.account"  :options="accounts" label="ACCOUNT" :label-color="validateInput(companyInfo.account)"/>
						<q-select standard v-model="companyInfo.costcenter"  :options="costCenter" label="COST CENTER" :label-color="validateInput(companyInfo.costcenter)"/>
						<q-select standard v-model="companyInfo.location"  :options="location" label="LOCATION" :label-color="validateInput(companyInfo.location)"/>
						<q-select standard v-model="companyInfo.rank"  :options="rank"  :rules="[formRules.required]" label="RANK"  @input="employmentStatusOption()" :label-color="validateInput(companyInfo.rank)"/>
						<q-select standard v-model="companyInfo.employmentStatus"  :options="empStatus" label="EMPLOYMENT STATUS" :label-color="validateInput(companyInfo.employmentStatus)" />
						<q-select standard v-model="companyInfo.immediateHead"  :options="immediateHeadOptions" label="IMMEDIATE HEAD" :label-color="validateInput(companyInfo.immediateHead)"/>
						<q-select standard v-model="companyInfo.divisionHead"  :options="divisionHeadOptions" label="DIVISION HEAD" :label-color="validateInput(companyInfo.divisionHead)"/>
						<q-input  standard v-model="companyInfo.endofProject" mask="date"  label="END OF PROJECT" :label-color="validateInput(companyInfo.endofProject)">
							<template v-slot:append>
								<i class="far fa-calendar-alt cursor-pointer" >
									<q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
										<q-date v-model="companyInfo.endofProject" @input="() => $refs.qDateProxy.hide()" />
										</q-popup-proxy>
									</i>
								</template>
							</q-input>

						</div>
						<div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl q-gutter-md">
							<div class="text-h6">Salary Information</div>
							

							<q-select standard v-model="governmentInfo.payClass" :options="payclass" label="PAY CLASS" @input="checkPayclass" v-show="ifConsultant" :label-color="validateInput(salaryInfo.payClass)"/>
							<q-select standard v-model="governmentInfo.region" option-value="id" option-label="region_no" :options="regionOptions" label="REGION" emit-value map-options v-show="isMWE" @input="getSector"/>
							<q-select standard v-model="governmentInfo.sector" option-value="id" option-label="sector" :options="sectorOptions" label="SECTOR" emit-value map-options v-show="isMWE" @input="getMWEInfo"/>
								<!--mask="#,###.##" fill-mask="0" reverse-fill-mask input-class="text-right"-->
							<q-input standard v-model="salaryInfo.basicSalary" label="BASIC" @input="calculateTotalSalary()" v-show="ifConsultant" :readonly="disabled" :label-color="validateInput(salaryInfo.basicSalary)"/>
							<q-input standard v-model="salaryInfo.allowance"   label="ALLOWANCE" @input="calculateTotalSalary()" v-show="ifConsultant" :readonly="disabled" :label-color="validateInput(salaryInfo.allowance)"/>
							<q-input standard v-model="salaryInfo.deminimis"   label="DEMINIMIS" @input="calculateTotalSalary()" v-show="ifConsultant" :readonly="disabled" :label-color="validateInput(salaryInfo.deminimis)"/>
							<q-input standard v-model="salaryInfo.totalSalary" label="TOTAL SALARY" readonly v-show="ifConsultant" :label-color="validateInput(salaryInfo.totalSalary)"/>

							<q-select standard v-model="salaryInfo.paymentMode"  :options="paymentmodes" @input="checkPaymentMode" label="MODE OF PAYMENT" v-show="ifConsultant" :label-color="validateInput(salaryInfo.paymentMode)"/>
							<q-select standard v-model="salaryInfo.paymentBank"  :options="payingbank" label="PAYING BANK" v-show="ifConsultant" :label-color="validateInput(salaryInfo.paymentBank)"/>
							<q-input standard v-model="salaryInfo.accountNumber" label="Account/Paycard Number" v-show="ifConsultant && isCash" class="caps" :label-color="validateInput(salaryInfo.accountNumber)"/>
							<q-input standard v-model="salaryInfo.fee" label="Fee" v-show="!ifConsultant" />

							<br/>
							<div class="text-h6">Educational Background</div>
							
							<q-select standard v-model="educationBackground.educationAttainment"  :options="educationalOptions" label="EDUCATIONAL ATTAINMENT" @input="checkEduc" :label-color="validateInput(educationBackground.educationAttainment)"/>
							<q-select standard v-model="educationBackground.course" :options="coursesOptions" label="COURSE" v-show="isCGrad" :label-color="validateInput(educationBackground.course)"/>
							<q-input standard v-model="educationBackground.school" label="SCHOOL GRADUATED" v-show="isCGrad" class="caps" :label-color="validateInput(educationBackground.school)"/>
							<q-input standard v-model="educationBackground.highschool" label="HIGH SCHOOL" v-show="isHSGrad" class="caps" :label-color="validateInput(educationBackground.highschool)"/>
						</div>
						<div class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl q-gutter-md" v-show="isTrainee">
							<div class="text-h6">Government Information</div>
							
							<q-input standard v-model="governmentInfo.sssNo" label="SSS #" :label-color="validateInput(governmentInfo.sssNo)"  mask="## - ####### - #" fill-mask="#"/>
							<q-input standard v-model="governmentInfo.hdmfNo" label="HDMF #" :label-color="validateInput(governmentInfo.hdmfNo)" mask="#### - #### - ####" fill-mask="#"/>
							<q-input standard v-model="governmentInfo.phicNo" label="PHIC #" :label-color="validateInput(governmentInfo.phicNo)" mask="## - ######### - #" fill-mask="#"/>
							<q-input standard v-model="governmentInfo.tinNo" label="TIN #" :label-color="validateInput(governmentInfo.tinNo)" mask="### - ### - ### - ###" fill-mask="#"/>
							<q-select standard v-model="governmentInfo.taxStatus"  :options="taxStatus" label="TAX  STATUS" :label-color="validateInput(governmentInfo.taxStatus)"/>
							<q-select standard v-model="governmentInfo.accountability" use-chips multiple :options="accountabilities" label="ACCOUNTABILITY"  counter hint="Total"/>
							<q-input standard v-model="companyInfo.shift_hours" label="SHIFT HOUR"  :label-color="validateInput(companyInfo.shift_hours)"/>
							<q-select standard v-model="companyInfo.period_rate"  :options="periodRates" label="PERIOD RATE" :label-color="validateInput(companyInfo.period_rate)"/>
						</div>
						<div class="col-12">
							<div class="row justify-center q-gutter-sm">
								<q-btn  color="teal" style="width: 150px" type="submit" >Save</q-btn>
							</div>
						</div>
					</div>
				</q-form>
				<q-dialog v-model="spinner" persistent>
					<q-card class="my-card transparent no-shadow">
						<q-card-section>
							<q-spinner-gears color="cyan" size="5.5em" />
						</q-card-section>
					</q-card>
				</q-dialog>

				<q-dialog v-model="prompt" persistent>
					<q-card style="min-width: 350px">
						<q-card-section>
						<div class="text-h6">Employee No. Initials (Ex. JE)</div>
						</q-card-section>

						<q-card-section class="q-pt-none">
						<q-input dense v-model="customInitials" autofocus @keyup.enter="prompt = false; customizeEmployeeNo();" @keypress="isLetter($event)" />
						</q-card-section>

						<q-card-actions align="right" class="text-primary">
						<q-btn flat label="Cancel" v-close-popup />
						<q-btn flat label="Customize" v-close-popup @click="customizeEmployeeNo()" />
						</q-card-actions>
					</q-card>
				</q-dialog>
			</div>
		</template>
		<script>
		import axios from 'axios';
		import mixins from '../../mixins';
import { ref } from 'vue';

		export default {

			name: 'CreateEmployee',

			data() {
				return {
					isCash:false,
					disabled:false,
					isMWE:false,
					ifConsultant:true,
					isTrainee:true,
					spinner: false,
					paymentmodes: [],
					departmentOptions: [],
					payingbank: [],
					payclass: [],
					coursesOptions:[],
					gender: [],
					civilStatus:[],
					relationship:[],
					accounts:[],
					costCenter:[],
					taxStatus:[],
					location:[],
					rank: [],
					periodRates:[],
					empStatus:[],
					regionOptions:[],
					sectorOptions:[],
					date: null,
					inputRules: [
					v => !!v || 'Name is required',
					],
					companyOptions:[],
					roleOptions:[
						{id: 2, role:'Employee'},
						{id: 1, role:'Admin'},
					],
					accountabilities:[],
					positions:[],
					immediateHeadOptions:[],
					divisionHeadOptions:[],
					educationalOptions:[],
					isHSGrad:false,
					isCGrad:false,
					bdayMonths:[],
					bdayDays:[],
					bdayYears:[],

					bdayDaysOptions:[],
					bdayYearsOptions:[],

					bdayMonth:null,
					bdayDay:null,
					bdayYear:null,

					personalInfo:{
						employee_no:null,
						firstname:null,
						middlename:null,
						lastname:null,
						birthdate: null,
						gender:null,
						civilStat:null,
						contactNo:null,
						landline:null,
						permanentAdd:null,
						presentAdd:null,
						email:null,
					},
					notifyPerson:{
						personToNotif:null,
						personToNotifContact:null,
						personToNotifRelationship:null,
						personToNotifEmailAdd:null
					},
					educationBackground:{
						educationAttainment:null,
						course:null,
						school:null,
						highschool:null
					},
					companyInfo:{
						company:ref(''),
						role: ref('Employee'),
						roleID: null,
						department:null,
						dateHired:null,
						position:null,
						account:null,
						costcenter:null,
						location:null,
						rank:null,
						employmentStatus:null,
						holdReason:null,
						holdDate:null,
						holdEffectivity:null,
						holdDuration:null,
						immediateHead:null,
						divisionHead:null,
						endofProject:null,
						shift_hours:null,
						period_rate:null,
					},
					previousCompany:{
						companyName:null,
						workDuration:null
					},
					salaryInfo:{
						basicSalary:0,
						allowance:0,
						deminimis:0,
						totalSalary: null,
						paymentMode:null,
						paymentBank:null,
						accountNumber:null,
						fee:null
					},
					healthInfo:{
						healthCardName:null,
						healthCardNo:null,
						insuranceName:null,
						insuranceNo:null
					},
					governmentInfo:{
						sssNo:null,
						hdmfNo:null,
						phicNo:null,
						tinNo:null,
						taxStatus:null,
						payClass:null,
						region:null,
						sector:null,
						accountability:null
					},

					prompt: false,

				}

			},
			created(){
				this.getDropdownProperties()
				if(this.userData.role_id !== 0){
					this.generateNewEmployeeID(this.userData.company)
				}
			},

			methods: {
				onSubmit : function(event){
					this.spinner = true

					let formData = new FormData();
					formData.append("employee_id", this.personalInfo.employee_no);
					formData.append("firstname", this.personalInfo.firstname);
					formData.append("middlename", this.personalInfo.middlename);
					formData.append("lastname", this.personalInfo.lastname);
					formData.append("email", this.personalInfo.email);
					formData.append("date_of_birth", this.bdayYear+'/'+this.bdayMonth+'/'+this.bdayDay);
					formData.append("gender", this.personalInfo.gender);
					formData.append("civil_status", this.personalInfo.civilStat);
					formData.append("mobile_number", this.personalInfo.contactNo);
					formData.append("landline_number", this.personalInfo.email);
					formData.append("address", this.personalInfo.permanentAdd);
					formData.append("city_address", this.personalInfo.presentAdd);

					formData.append("person_to_notif", this.notifyPerson.personToNotif);
					formData.append("person_to_notif_contact", this.notifyPerson.personToNotifContact);
					formData.append("relation_to_person_to_notif", this.notifyPerson.personToNotifRelationship);
					formData.append("email_of_person_to_notif", this.notifyPerson.personToNotifEmailAdd);

					formData.append("educational_attainment", this.educationBackground.educationAttainment);
					formData.append("college_course", this.educationBackground.course);
					formData.append("college_school", this.educationBackground.school);
					formData.append("high_school", this.educationBackground.highschool);

					formData.append("company", this.companyInfo.company);
					formData.append("role", this.companyInfo.roleID);
					formData.append("department", this.companyInfo.department);
					formData.append("date_hired", this.companyInfo.dateHired);
					formData.append("position", this.companyInfo.position);
					formData.append("account", this.companyInfo.account);
					formData.append("cost_center", this.companyInfo.costcenter);
					formData.append("location", this.companyInfo.location);
					formData.append("rank", this.companyInfo.rank);
					formData.append("employment_status", this.companyInfo.employmentStatus);
					formData.append("hold_reason", this.companyInfo.holdReason);
					formData.append("hold_date", this.companyInfo.holdDate);
					formData.append("hold_effectivity", this.companyInfo.holdEffectivity);
					formData.append("hold_duration", this.companyInfo.holdDuration);
					formData.append("immediate_head", this.companyInfo.immediateHead);
					formData.append("division_head", this.companyInfo.divisionHead);
					formData.append("end_of_project", this.companyInfo.endofProject);

					formData.append("previous_company", this.previousCompany.companyName);
					formData.append("previous_company_length_of_stay", this.previousCompany.workDuration);

					formData.append("basic_salary", this.salaryInfo.basicSalary);
					formData.append("allowance", this.salaryInfo.allowance);
					formData.append("deminimis", this.salaryInfo.deminimis);
					formData.append("total_salary", this.salaryInfo.totalSalary);
					formData.append("payment_mode", this.salaryInfo.paymentMode);
					formData.append("paying_bank", this.salaryInfo.paymentBank);
					formData.append("account_number", this.salaryInfo.accountNumber);
					formData.append("fee", this.salaryInfo.fee);

					formData.append("healthcard_name", this.healthInfo.healthCardName);
					formData.append("healthcard_number", this.healthInfo.healthCardNo);
					formData.append("insurance_name", this.healthInfo.insuranceName);
					formData.append("insurance_number", this.healthInfo.insuranceNo);

					formData.append("sss_number", this.governmentInfo.sssNo);
					formData.append("hdmf_number", this.governmentInfo.hdmfNo);
					formData.append("philhealth_number", this.governmentInfo.phicNo);
					formData.append("tin_number", this.governmentInfo.tinNo);
					formData.append("bir_number", this.governmentInfo.tinNo);
					formData.append("tax_status", this.governmentInfo.taxStatus);
					formData.append("pay_class", this.governmentInfo.payClass);
					formData.append("regionID", this.governmentInfo.region);
					formData.append("sectorID", this.governmentInfo.sector);
					formData.append("accountability", this.governmentInfo.accountability);
					formData.append("shift_hours", this.companyInfo.shift_hours);
					formData.append("period_rate", this.companyInfo.period_rate);
					axios.create({
						baseURL : this.apiUrl,
						headers : {'Authorization' : `Bearer ${this.token}`}
					}).post('/employee/create',formData)
					.then((res) => {
						this.generateNewEmployeeID()
						this.resetForm()
						this.spinner = false
						this.$q.notify({
							message: 'Successful',
							color: 'green',
							position:'center',
							actions: [
						{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
						]
					})
					}).catch((error) => {
						var message = error.response.data.exception[1].message
						var index = message.indexOf("1062");
						var final = message.substring(index + 4);
						this.$q.notify({
							message: final,
							color: 'negative',
							position:'center',
							actions: [
						{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
						]
					})
						this.spinner = false
					});
				},


				calculateTotalSalary:function(){

					var total =  parseInt(this.salaryInfo.basicSalary) + parseInt(this.salaryInfo.allowance) + parseInt(this.salaryInfo.deminimis);
					if(isNaN(total)){
						total = 0;
					}

					this.salaryInfo.totalSalary = parseInt(total);


				},

				checkPayclass:function(){
					var type = this.governmentInfo.payClass
					this.governmentInfo.region = ''
					this.governmentInfo.sector = ''
					if(type == 'MONTHLY - MWE' || type == 'DAILY - MWE'){
						this.isMWE = true
						this.displayRegions()
						this.disabled = true
					}else{
						this.disabled = false
						this.isMWE = false
						this.salaryInfo.basicSalary = 0
						this.salaryInfo.allowance = 0
						this.salaryInfo.deminimis = 0
						this.salaryInfo.totalSalary = 0
					}
				},

				checkPaymentMode:function(){
					var pmode = this.salaryInfo.paymentMode
					if(pmode != 'CASH'){
						this.isCash = true
					}else{
						this.isCash = false
					}
				},

				checkDepartment:function(){
					var dept = this.companyInfo.department
					axios.create({
						baseURL: this.apiUrl,
						headers: {
							'Authorization': `Bearer ${this.token}`
						}}).get(`/admin/position/${dept}`).then((res) => {
						this.positions = res.data.data
						setTimeout(() => {
							this.spinner = false
						},500)
					})
				},

				displayRegions: function() {
					axios.create({
						baseURL: this.apiUrl,
						headers: {
							'Authorization': `Bearer ${this.token}`
						}})
					.get(`/admin/regions`)
					.then((res) => {

						this.regionOptions = res.data.data;
						setTimeout(() => {
							this.spinner = false
						},500)
					})
				},

				getSector:function(){
					axios.create({
						baseURL: this.apiUrl,
						headers: {
							'Authorization': `Bearer ${this.token}`
						}})
					.get(`/admin/region/sectors/${this.governmentInfo.region}`)
					.then((res) => {
						this.governmentInfo.sector = ''
						this.salaryInfo.basicSalary = ''
						this.salaryInfo.allowance = ''
						this.salaryInfo.deminimis = ''
						this.salaryInfo.totalSalary = ''
						this.sectorOptions = res.data.data;
						setTimeout(() => {
							this.spinner = false
						},500)
					})
				},

				getMWEInfo:function(){

					axios.create({
						baseURL: this.apiUrl,
						headers: {
							'Authorization': `Bearer ${this.token}`
						}})
					.get(`/admin/check/mwe/${this.governmentInfo.region}/${this.governmentInfo.sector}/${this.governmentInfo.payClass}`)
					.then((res) => {
						if(res.data.data != null){
							this.salaryInfo.basicSalary = res.data.data.basic
							this.salaryInfo.allowance = res.data.data.allowance
							this.salaryInfo.deminimis = res.data.data.deminimis
							this.salaryInfo.totalSalary = res.data.data.total
						}else{
							this.salaryInfo.basicSalary = 0
							this.salaryInfo.allowance = 0
							this.salaryInfo.deminimis = 0
							this.salaryInfo.totalSalary = 0
						}

					})
				},


				employmentStatusOption:function(){
					var ranks = this.companyInfo.rank.toLowerCase();
					if(ranks === 'trainee' || ranks === 'consultant'){
						this.companyInfo.employmentStatus = this.companyInfo.rank
					}

					if(ranks === 'trainee'){
						this.isTrainee = false;
						var traineeID = this.personalInfo.employee_no.replace('SGCC','TRAI')
						this.personalInfo.employee_no = traineeID
					}else{
						this.isTrainee = true;
						var empID = this.personalInfo.employee_no.replace('TRAI','SGCC')
						this.personalInfo.employee_no = empID
					}

					if(ranks === 'consultant'){
						this.ifConsultant = false;
						this.isTrainee = false;
					}else{
						this.ifConsultant = true;
					}


				},

				resetForm:function(){
					var self = this;
					Object.keys(this.personalInfo).forEach(function(key, index) {
						self.personalInfo[key] = '';
					});

					Object.keys(this.notifyPerson).forEach(function(key, index) {
						self.notifyPerson[key] = '';
					});

					Object.keys(this.educationBackground).forEach(function(key, index) {
						self.educationBackground[key] = '';
					});

					Object.keys(this.companyInfo).forEach(function(key, index) {
						self.companyInfo[key] = '';
					});

					Object.keys(this.previousCompany).forEach(function(key, index) {
						self.previousCompany[key] = '';
					});

					Object.keys(this.salaryInfo).forEach(function(key, index) {
						self.salaryInfo[key] = '';
					});

					Object.keys(this.healthInfo).forEach(function(key, index) {
						self.healthInfo[key] = '';
					});

					Object.keys(this.governmentInfo).forEach(function(key, index) {
						self.healthInfo[key] = '';
					});

				},

				validateInput:function(value){
					if (value == null || value == '' || value == 'NULL' || value == 'null' || value == "0000-00-00" || value == 0){
						return 'red';
					} else {
						return 'green';
					}
				},


				getDropdownProperties:function(){

					axios.create({
						baseURL : this.apiUrl,
						headers : {'Authorization' : `Bearer ${this.token}`}
					}).get('/employee/dropdown/properties')
					.then((res) => {

						this.paymentmodes = res.data.data.paymentMode
						this.payingbank = res.data.data.bank
						this.payclass = res.data.data.payClass
						this.gender = res.data.data.gender
						this.accounts = res.data.data.account
						//this.positions = res.data.data.positions
						this.costCenter = res.data.data.costCenter
						this.location = res.data.data.location
						this.rank = res.data.data.rank
						this.civilStatus  = res.data.data.civilStatus
						this.relationship = res.data.data.relationship
						this.taxStatus = res.data.data.taxStatus
						this.empStatus = res.data.data.empStatus
						this.accountabilities = res.data.data.accountability
						this.immediateHeadOptions = res.data.data.immediatehead
						this.divisionHeadOptions = res.data.data.divisionhead
						this.educationalOptions = res.data.data.educAttaintment
						this.coursesOptions = res.data.data.courses
						this.companyOptions = res.data.data.companies
						this.departmentOptions = res.data.data.department
						this.bdayMonths = res.data.data.bdayMonths
						this.bdayDays = res.data.data.bdayDays
						this.bdayYears = res.data.data.bdayYears
						this.periodRates = res.data.data.periodRate

					});
				},

				filterDays (val, update, abort) {
					update(() => {
						const day = val.toLowerCase()
						this.bdayDaysOptions = this.bdayDays.filter(v => v.toLowerCase().indexOf(day) > -1)
					})
				},

				filterYears (val, update, abort) {
					update(() => {
						const year = val.toLowerCase()
						this.bdayYearsOptions = this.bdayYears.filter(v => v.toLowerCase().indexOf(year) > -1)
					})
				},

				generateNewEmployeeID:function(id){
					let formData = new FormData();
					formData.append("id", id);
					axios.create({
						baseURL : this.apiUrl,
						headers : {'Authorization' : `Bearer ${this.token}`}
					}).post('/employeeno/generate', formData)
					.then((res) => {
						this.personalInfo.employee_no = res.data.data
					});
				},

				checkEduc:function(){
					var educ = this.educationBackground.educationAttainment
					if(educ == 'HIGH SCHOOL'){
						this.isHSGrad = true;
						this.isCGrad = false;
					}else{
						this.isHSGrad = false;
						this.isCGrad = true;
					}
				},

			}
		}
		</script>
		<style lang="css" scoped>
		</style>
