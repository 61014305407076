<template>
  <q-layout view="lHh LpR fFf">
    <q-header elevated style="background-color: #353c48;">
      <q-toolbar>
        <q-btn flat class="q-mr-md" @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Menu" icon="fas fa-bars" text-color="white">
          <q-tooltip anchor="top middle" content-class="bg-black" self="bottom middle" :offset="[20, 20]">
            <span v-if="leftDrawerOpen"><strong>Close Drawer</strong></span>
            <span v-else><strong>Open Drawer</strong></span>
          </q-tooltip>
        </q-btn>

        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%; margin-top: 5px; font-size: 20px; text-align: center;">
          <p>{{ currentTime }}</p>
          <p style="margin-top: -20px;">{{ timeZone }}</p>
        </div>

        <div style="display: flex; margin-left: auto; margin-right: 0;">
          <!-- START:: Button for notifications -->
        <q-btn class="q-mr-sm" stretch flat icon="fas fa-bell" text-color="white" style="width: 30px;" size="sm" @click="notificationBell">
          <q-badge class="absolute-bottom-right" color="orange" text-color="black" v-if="count != 0" v-html="count"></q-badge>
          <q-tooltip anchor="top middle" content-class="bg-black" self="bottom middle" :offset="[10, 10]">
            <strong>Notifications</strong>
          </q-tooltip>
        </q-btn>

          <div class="q-pa-md">
            <q-avatar @click="toggleMenu" class="cursor-pointer" size="40px">
              <img @click="toggleMenu" :src="apiUrl + userData.image" alt="User Avatar" />
            </q-avatar>
            <q-menu 
              v-model="showMenu" 
              auto-close
              :offset="[0, 5]" 
              transition-show="scale" 
              transition-hide="scale">
              <q-list style="min-width: 200px">
                <q-item clickable @click="myProfile">
                  <div style="display: flex; justify-items: center; align-items: center; gap: 10px">
                    <i class="fa fa-user"></i>
                    <q-item-section>Profile</q-item-section>
                  </div>
                </q-item>
                <q-item clickable @click="changePassword">
                  <div style="display: flex; justify-items: center; align-items: center; gap: 10px">
                    <i class="fa fa-key"></i>
                    <q-item-section>Change Password</q-item-section>
                  </div>
                </q-item>
                <q-item clickable @click="logOut">
                  <div style="display: flex; justify-items: center; align-items: center; gap: 10px">
                    <i class="fa fa-power-off"></i>
                    <q-item-section>Logout</q-item-section>
                  </div>
                </q-item>
              </q-list>
            </q-menu>
          </div>
        </div>

      </q-toolbar>
    </q-header>
    <q-drawer side="left" elevated v-model="leftDrawerOpen" bordered class="left-panel" :width="300">
      <q-list bordered separator>
        <q-item-label class="bg-white hitler" style="padding: -16px;">
          <h4 class="no-margin">
            <q-item clickable tag="a" href="/#/admin/dashboard" class="text-center" style="display: flex; flex-direction: column;">
              <div class="full-width" style="color: black;">
                <p style="font-size: 1.5rem;">JE310 HRIS</p>
              </div>
              <div style="margin: auto;">
                <img style="border-radius: 25px;" width="80" height="80" :src="apiUrl + userData.image" alt="">
                <p style="font-size: 1.2rem; font-weight: bold; color: black;">{{ userData.firstname }}</p>
                <p style="font-size: 1rem; margin-top: -25px;">
                  {{ userData.role_id == 0 ? "Superadmin" 
                     : userData.role_id == 1 ? "Admin"
                     : "Employee" 
                  }}
                </p>
              </div>
            </q-item>
          </h4>
        </q-item-label>
        <q-separator />
        <div class="sidebg">
          <q-scroll-area style="height: calc(100%); border-right: 1px solid #ddd">
            <template v-for="(navigation, index) in navigations">
              <template v-if="navigation.sub === undefined">
                <q-item clickable v-bind:key="index" :to=" '/' + selectedModule.code + navigation.route">
                    <q-item-section avatar>
                    <i class="fa" :class="navigation.iconClass"></i>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label style="font-weight: 300;">{{navigation.name}}</q-item-label>
                  </q-item-section>
                </q-item>
              </template>
              <template v-if="navigation.sub">
                <q-expansion-item v-bind:key="index" :label="navigation.name" dense-toggle expand-separator :content-inset-level="0.5" :class="[qExpandItem, navigation.textColor]" header-style="font-weight: bold">
                  <q-item clickable v-for="(sub, x) in navigation.sub" v-bind:key="x" :to=" '/' + selectedModule.code + sub.route" :class="sub.textColor" :active-class="sub.activeColor">
                    <q-item-section avatar>
                      <i class="fa" :class="sub.iconClass"></i>
                    </q-item-section>
                    <q-item-section>
                      <q-item-label><strong>{{sub.name}}</strong></q-item-label>
                    </q-item-section>
                  </q-item>
                </q-expansion-item>
              </template>
            </template>
          </q-scroll-area>
        </div>
      </q-list>
    </q-drawer>
    <q-page-container>
      <vue-page-transition name="fade-in-left">
        <router-view style="background-color: #f1f2f7;"/>
      </vue-page-transition>
      <ChangePassword></ChangePassword>
      <Profile></Profile>
      <LogoutDialog></LogoutDialog>

      <!-- conversation start here -->
      <!--<ChatModal></ChatModal>-->
      <!-- conversation end here -->
    </q-page-container>
  </q-layout>
</template>
<script>
import axios from 'axios';
import ChangePassword from '../components/ChangePassword.vue';
import Profile from '../components/Profile.vue';
import LogoutDialog from '../components/LogoutDialog.vue';
import jsPDF from 'jspdf';
//import ChatModal from '../components/announcement/AdminChatModal';

export default {
  name: 'Dashboard',
  components: {
    "ChangePassword": ChangePassword,
    "Profile": Profile,
    "LogoutDialog": LogoutDialog,
    //"ChatModal": ChatModal
  },

  data: () => ({
    isActive: false,
    selectedModule: {},
    isEmployee: true,
    leftDrawerOpen: true,
    navigations: [],
    subNavigations: [],
    qExpandItem: 'qExpandItem',
    showApprovals: false,
    fullname: null,
    allowedMenu: [],
    uniqueMenu: [],
    currentModule: '',
    moduleHeader: 'module-header',
    allUnread: 0,
    hrUnread: 0,
    notif_list: [],
    count: '',
    chat: [],
    chatNotif: 0,
    drawerRight: false,
    mychatmodal: true,
    chatEmployeeList: [],
    chatEmployeeTemp: [],
    sendToALl: { id: 0, empID: 'none', fullname: 'ANNOUNCEMENT', title: 'All Employees', type: 'all' },
    sendToHR: { id: 0, empID: 'none', fullname: 'HUMAN RESOURCES', title: 'HR Department', type: 'hr' },
    searchKey: '',
    drawerOpen: false,
    showMenu: false,
    currentTime: '',
    timeZone: null,
    apiUrl: process.env.VUE_APP_API_URL,
    employeeModules: null
  }),

  sockets: {
    totalNotif: function(data) {
      this.getChatNotification();
      this.getChatEmpList();
    },

    read_badge: function(data) {
      if (data.receiver != 0) {
        this.getChatEmpList();
      } else {
        this.getChatEmpList();
        //this.getChatSendAll();
      }
    },

    read_announcement: function(data) {
      //console.log("read_announcement: ", data);
      //this.getChatEmpList();
      this.count = this.count + 1;
    },
  },

  mounted: function() {
     if(this.$route.path == '/payslip/payslip-dashboard' || this.$route.path == '/payslip'){
        this.leftDrawerOpen = false;
     }
    if (this.userData.username == 'NewApplicant') {
      this.isEmployee = false;
      this.leftDrawerOpen = false;
      this.selectedModule.bgClass = '#353c48';
      this.$router.push('/recruitment/employment-application').catch(() => {});
    } else {
      this.fullname = this.userData.firstname + ' ' + this.userData.lastname
      for (let i in this.modules) {
        if (this.modules[i]['code'] == this.$route.params.module) {
          this.selectedModule = this.modules[i];
        }
      }
      this.getEmployeeModules();
      // this.setMenu(); //for navi menu
      this.currentModule = this.$route.params.module;
    }
  },

  created() {
    console.log('image:' + this.userData.image)
    this.eventHub.$on('showChatModal', this.getChatEmpList);
    this.displayNotification();
    //this.getChatEmpList();
    //this.getChatNotification();
    this.isChatViewer = (this.userData.department == 1 || this.userData.id == 1) ? true : false; //HR and ADMIN
    // console.log(this.isChatViewer)
    this.getTimeZone()
    this.timeInterval = setInterval(this.updateTime, 1000);
  },

  computed: {
    isDrawerOpen() {
      // Open the drawer for a specific named route
      return this.$route.name === 'dashboard'; // Replace with your named route
    }
  },

  methods: {
    //START:: CHAT
    showChatModal: function(obj) {
      //console.log(obj.id)
      this.eventHub.$emit('openChat', { stat: true, obj: obj });
    },

    filterEmployee: function() {
      if (this.searchKey !== null) {
        let formData = new FormData();
        formData.append("keyword", this.searchKey);
        axios.create({
            baseURL: this.apiUrl,
            headers: { 'Authorization': `Bearer ${this.token}` }
          }).post('/search/receiver', formData)
          .then((res) => {
            this.chatEmployeeList = res.data.data
            //console.log(res.data.data)
          }).catch((error) => {
            console.log(error.response.status)
          });
      }
    },

    getChatEmpList: function() {
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get(`/chat/employee/lists`)
        .then((res) => {
          this.chatEmployeeList = res.data.data;
          this.chatEmployeeTemp = res.data.data;
          // console.log(this.chatEmployeeList)
        })
    },

    readAnnouncement: function() {
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get(`/employee/readAnnouncement`)
        .then((res) => {
          // console.log("res: ", res.data.data);
          this.count = res.data.data;
        });
    },

    notificationBell: function() {
      //$router.replace('/announcement/announcement-list')
      const path = `/announcement/announcement-list`;
      if (this.$route.path !== path) {
        this.$router.push(path)
        location.reload();
      }
    },

    displayNotification: function() {
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get(`/employee/notification`)
        .then((res) => {
          this.notif_list = res.data.data;
          //this.count = res.data.count;
        });
    },

    getChatNotification: function() {
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get(`/employee/chat/notif`)
        .then((res) => {
          this.allUnread = 0 //(res.data.allUnread.totalAllUnread == '') ? 0 : res.data.allUnread.totalAllUnread
          this.hrUnread = res.data.hrUnread.totalHRUnread
          //console.log(res.data.allUnread)
          //console.log(res.data.hrUnread)
          this.chatNotif = res.data.data.totalUnread
          //console.log(res.data.data.totalUnread)
        });
    },

    selectMenu: function(module) {
      this.$router.replace('/' + module)
      location.reload();
    },

    setMenu: function() {
      const menuList = this.modules //get menu list
      const allowedMenu = this.userData //get allowed menu in token
      let isAdmin = (this.usersModules[1] !== undefined) ? true : false;
      if (isAdmin) {
        this.allowedMenu = menuList
      } else {
        // console.log(JSON)
        for (let i in menuList) {
          if (menuList[i]['allowedmenu'] == 'forms' || menuList[i]['allowedmenu'] == 'leaves' || menuList[i]['allowedmenu'] == 'announcement' || menuList[i]['allowedmenu'] == 'payslip') { //make form and leaves default
            this.allowedMenu.push(menuList[i]);
          }
          for (let key in allowedMenu.modules) {
            if (menuList[i]['allowedmenu'] === allowedMenu.modules[key]['name']) {
              this.allowedMenu.push(menuList[i]);
            }
          }
        }
      }

      var unique = this.allowedMenu.filter(function(elem, index, self) { //remove duplicate menu
        return index === self.indexOf(elem);
      })

      this.uniqueMenu = unique;
    },

    logOut: function() {
      this.eventHub.$emit('logOut', { status: true });
    },

    changePassword: function() {
      this.eventHub.$emit('changePassword', { status: true });
    },

    myProfile: function() {
      this.eventHub.$emit('myProfile', { status: true });
    },

    toggleMenu() {
      this.showMenu = !this.showMenu;
    },

    handleOption(option) {
      // Handle menu item click
      this.$q.notify({
        message: `${option} clicked!`,
        color: 'primary',
        position: 'top',
      });
      this.showMenu = false;
    },

    getEmployeeModules(){
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .post(`/employees/get/modules`)
        .then((res) => {
          // console.log("res: ", res.data.data);
          this.employeeModules = res.data.data;
          this.setNav();
        });
    },

    setNav: function() {
      const uniqueNavigations = new Set();

            // links for admin
      const defaultLinks = {      
              "employees": {
              textColor: "navTitle",
              activeColor: "navTitle",
              iconClass: "fa-user",
              name: "Employee Management",
              route: "/sms-blast",
              sub: [
                {
                  textColor: 'colorEmployee',
                  activeColor: 'activeColorEmployee',
                  iconClass: 'fa-chart-bar',
                  name: "Dashboard",
                  route: "/dashboard"
                },
                {
                  textColor: 'colorEmployee',
                  activeColor: 'activeColorEmployee',
                  iconClass: 'fa-user-plus',
                  name: "Create Employee",
                  route: "/create"
                },
                {
                  textColor: 'colorEmployee',
                  activeColor: 'activeColorEmployee',
                  iconClass: 'fa-users',
                  name: "List of Employees",
                  route: "/list"
                },
                {
                  textColor: 'colorEmployee',
                  activeColor: 'activeColorEmployee',
                  iconClass: 'fa-user-tag',
                  name: "Hold Employees",
                  route: "/hold-employees"
                },
                {
                  textColor: 'colorEmployee',
                  activeColor: 'activeColorEmployee',
                  iconClass: 'fa-user-times',
                  name: "Inactive Employees",
                  route: "/inactive-employees"
                },
                {
                  textColor: 'colorEmployee',
                  activeColor: 'activeColorEmployee',
                  iconClass: 'fa-user-lock',
                  name: "Module Permissions",
                  route: "/module-permissions"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: "fa-user-edit",
                  name: "Employee Logs",
                  route: "/employee-logs",
                },
                // 06072022
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: "fa-user-edit",
                  name: "For Review",
                  route: "/for-review",
                },
                // 01092023
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: "fa-user-lock",
                  name: "New Applicants",
                  route: "/new-employee",
                },
              {
                textColor: 'colorEmployee',
                activeColor: 'activeColorEmployee',
                iconClass: 'fa-podcast',
                name: "Form",
                route: "/exit-interview"
              },
              {
                textColor: 'colorEmployee',
                activeColor: 'activeColorEmployee',
                iconClass: 'fa-file-excel',
                name: "Report",
                route: "/exit-interview-report"
              },
            ],
              },
              
              "payRoll": {
              textColor: "colorEmployee",
              activeColor: "activeColorEmployee",
              iconClass: "fa-money-bill",
              name: "Payroll",
              route: "/sms-blast",
              sub: [
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-file-invoice-dollar',
                  name: "Process Payroll",
                  route: "/process"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-file-invoice-dollar',
                  name: "Payroll",
                  route: "/payroll-history"
                },
              {
                textColor: "colorEmployee",
                activeColor: "activeColorEmployee",
                iconClass: 'fa-calendar-plus',
                name: "Adjustment/Deduction",
                route: "/adjusments"
              },
              {
                textColor: "colorEmployee",
                activeColor: "activeColorEmployee",
                iconClass: 'fa-calendar-minus',
                name: "Loan Deduction",
                route: "/deductions"
              },
              {
                textColor: "colorEmployee",
                activeColor: "activeColorEmployee",
                iconClass: 'fa-newspaper',
                name: "Detailed Payslip",
                route: "/detailed-payslip"
              },
              {
                textColor: "colorEmployee",
                activeColor: "activeColorEmployee",
                iconClass: 'fa-list-alt',
                name: "SSS",
                route: "/sss-table"
              },
              {
                textColor: "colorEmployee",
                activeColor: "activeColorEmployee",
                iconClass: 'fa-list-alt',
                name: "PhilHealth",
                route: "/philHealth-table"
              },
              {
                textColor: "colorEmployee",
                activeColor: "activeColorEmployee",
                iconClass: 'fa-list-alt',
                name: "Pag-ibig",
                route: "/pagibig-table"
              },
              {
                textColor: "colorEmployee",
                activeColor: "activeColorEmployee",
                iconClass: 'fa-list-alt',
                name: "Tax-Table",
                route: "/tax-table"
              },
              {
                textColor: "colorEmployee",
                activeColor: "activeColorEmployee",
                iconClass: 'fa-list-alt',
                name: "Tax Status",
                route: "/tax-status-table"
              },
              {
                textColor: "colorEmployee",
                activeColor: "activeColorEmployee",
                iconClass: 'fa-list-alt',
                name: "Holiday & OT Rates",
                route: "/holiday-otrates-table"
              },
            ]
              },

              "timeKeeping": {
              textColor: "colorEmployee",
              activeColor: "activeColorEmployee",
              iconClass: "fa-clock",
              name: "Timekeeping",
              route: "/sms-blast",
              sub: [
              {
                textColor: 'colorEmployee',
                activeColor: "activeColorEmployee",
                iconClass: 'fa-chart-bar',
                name: "Dashboard",
                route: "/home/dashboard"
              },
              {
                textColor: 'colorEmployee',
                activeColor: "activeColorEmployee",
                iconClass: 'fa-user-clock',
                name: "Upload Timelogs",
                route: "/timelogs/upload"
              },
              {
                textColor: 'colorEmployee',
                activeColor: "activeColorEmployee",
                iconClass: 'fa-stopwatch',
                name: "Monthly Detailed TK",
                route: "/detailed/tk"
              },
              {
                textColor: 'colorEmployee',
                activeColor: "activeColorEmployee",
                iconClass: 'fa-clock',
                name: "Hourly Detailed TK",
                route: "/hourly/detailed/tk"
              },
              {
                textColor: 'colorEmployee',
                activeColor: "activeColorEmployee",
                iconClass: 'fa-file-invoice-dollar',
                name: "TK Summary",
                route: "/tk/summary"
              },
              {
                textColor: 'colorEmployee',
                activeColor: "activeColorEmployee",
                iconClass: 'fa-user-clock',
                name: "TK Pending Request",
                route: "/tk/pending/request"
              },
              {
                textColor: 'colorEmployee',
                activeColor: "activeColorEmployee",
                iconClass: 'fa-calendar-alt',
                name: "TK Request History",
                route: "/tk/request/history"
              },
              {
                textColor: 'colorEmployee',
                activeColor: "activeColorEmployee",
                iconClass: 'fa-gifts',
                name: "Holidays",
                route: "/holidays"
              },
              {
                textColor: 'colorEmployee',
                activeColor: "activeColorEmployee",
                iconClass: 'fa-calendar-alt',
                name: "Periodic Table",
                route: "/periodic/table"
              },
              ]
              },

              "leaves": {
              textColor: "colorEmployee",
              activeColor: "activeColorEmployee",
              iconClass: "fa-person-walking-arrow-right",
              name: "Leaves",
              route: "/",
              sub: [
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-copy',
                  name: "My Applications",
                  route: "/my-leave-applications"
                }, 
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-file-alt',
                  name: "Application Form",
                  route: "/application-form"
                }, 
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-list-ol',
                  name: "My Leave Credits",
                  route: "/my-leave-credits"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-user-check',
                  name: "My Approvals",
                  route: "/my-leave-approvals"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-user-edit',
                  name: "Set Approvers",
                  route: "/set-leave-approvers"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-folder-open',
                  name: "Employee Applications",
                  route: "/employee-leave-applications"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-list',
                  name: "Employee Leave Credits",
                  route: "/employee-leave-credits"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-file-contract',
                  name: "Special Leave Applications",
                  route: "/special-leave-applications"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-chart-bar',
                  name: "Leave Reports",
                  route: "/leave-reports"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-file-contract',
                  name: "Remaining Leaves",
                  route: "/remaining-leaves"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-history',
                  name: "Employee Leave History",
                  route: "/employee-leave-history"
                },
              ]
              },

              "training": {
              textColor: "colorEmployee",
              activeColor: "activeColorEmployee",
              iconClass: "fa-graduation-cap",
              name: "Training",
              route: "/sms-blast",
              sub: [
                {
                  textColor: 'colorEmployee',
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-book-open',
                  name: "E-Learning",
                  route: "/lessons"
                },
              ]
              },

              "announcement": {
              textColor: "colorEmployee",
              activeColor: "activeColorEmployee",
              iconClass: "fa-bullhorn",
              name: "Announcement",
              route: "/",
              sub: [
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-chart-bar',
                  name: "Admin Dashboard",
                  route: "/admin-dashboard"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-bullhorn',
                  name: "List of Announcements",
                  route: "/announcement-list"
                }
              ]
              },

              "backend": {
              textColor: "colorEmployee",
              activeColor: "activeColorEmployee",
              iconClass: "fa-code",
              name: "Government Reports",
              route: "/",
              sub: [
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-list-alt',
                  name: "SSS Employee Contribution",
                  route: "/sss-employee-contribution"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-list-alt',
                  name: "Philhealth Employee Contribution",
                  route: "/philhealth-employee-contribution"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-list-alt',
                  name: "Pag-ibig Employee Contribution",
                  route: "/pagibig-employee-contribution"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-list-alt',
                  name: "Alphalist",
                  route: "/alphalist"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-list-alt',
                  name: "Total Gov Contribution",
                  route: "/total-gov-contribution"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: "fa-table",
                  name: "Form 2316",
                  route: "/form-2316",
                },
              ]
              },
      };

      const superAdminOnly = {
        "settings": {
              "textColor": "colorEmployee",
              "activeColor": "activeColorEmployee",
              "iconClass": "fa-gear",
              "name": "Settings",
              "route": "/",
              "sub": [
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Accounts",
                  "route": "/account/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Accountability",
                  "route": "/accountability/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Adjustment",
                  "route": "/adjustment/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Banks",
                  "route": "/bank/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Civil Status",
                  "route": "/civil-status/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Company",
                  "route": "/company/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Cost Center",
                  "route": "/cost-center/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Coverage",
                  "route": "/coverage/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Courses",
                  "route": "/course/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Deactivation Reasons",
                  "route": "/deactivation-reasons/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Deduction",
                  "route": "/deduction/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Departments",
                  "route": "/department/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Division Head",
                  "route": "/division-head/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Employee Status",
                  "route": "/status/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Employment Status",
                  "route": "/employment-status/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "GCA",
                  "route": "/gca/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Immediate Head",
                  "route": "/immediate-head/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Locations",
                  "route": "/location/list"
                },
                // {
                //   "textColor": "colorEmployee",
                //   "activeColor": "activeColorEmployee",
                //   "iconClass": "fa-users-cog",
                //   "name": "Module Permissions Access",
                //   "route": "/module-permissions-access"
                // },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "MWE-Daily",
                  "route": "/mwe-daily/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "MWE-Monthly",
                  "route": "/mwe-monthly/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Payclass",
                  "route": "/payclass/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Payment Mode",
                  "route": "/payment-mode/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Person to Notify Relationship",
                  "route": "/relationship/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Positions",
                  "route": "/position/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Provinces",
                  "route": "/province/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Ranks",
                  "route": "/rank/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Region",
                  "route": "/region/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Sector/Industry",
                  "route": "/sector/list"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Set As Approver",
                  "route": "/set-as-approver"
                },
                {
                  "textColor": "colorEmployee",
                  "activeColor": "activeColorEmployee",
                  "iconClass": "fa-users-cog",
                  "name": "Tax Status",
                  "route": "/tax-status/list"
                }
              ]
              },
      }

      if(this.userData.role_id == 1){
        this.selectedModule.code = 'admin'
        for (let i in defaultLinks) {
            this.navigations.push(defaultLinks[i]);
            if (this.$route.name == "Dashboard") {
              this.$router.push('/admin/dashboard');
            }
        }
      }else if(this.userData.role_id == 0){
        this.selectedModule.code = 'superAdmin'
        for (let i in defaultLinks) {
            if (this.$route.name == "Dashboard") {
              this.$router.push('/employee-management/dashboard');
            }
            if (!uniqueNavigations.has(defaultLinks[i].name)) {
              this.navigations.push(defaultLinks[i]);
              uniqueNavigations.add(defaultLinks[i].name);
            }
            if (!uniqueNavigations.has(superAdminOnly.settings.name)) {
              this.navigations.push(superAdminOnly.settings);
              uniqueNavigations.add(superAdminOnly.settings.name);
            }
        }
      }else{
        this.selectedModule.code = 'employee';
        this.$router.push('/employee/attendance');

        if(this.employeeModules !== null || undefined){
          const employeeModules = JSON.parse(this.employeeModules);
          const filteredModules = employeeModules
            .filter(item => item.enabled)
            .map(item => ({
              ...item,
              sub: item.sub ? item.sub.filter(subItem => subItem.enabled) : []
          }));
          for (let i in filteredModules) {
            this.navigations.push(filteredModules[i]);
          }
        // console.log('filtered: ' + JSON.stringify(filteredModules));
        }else{
          let mods = {
            "timeKeeping": {
              textColor: "colorEmployee",
              activeColor: "activeColorEmployee",
              iconClass: "fa-clock",
              name: "Timekeeping and Forms",
              route: "/sms-blast",
              sub: [
              {
                textColor: 'colorEmployee',
                activeColor: "activeColorEmployee",
                iconClass: 'fa-user',
                name: "Attendance",
                route: "/attendance"
              },
              {
                textColor: 'colorEmployee',
                activeColor: "activeColorEmployee",
                iconClass: 'fa-calendar',
                name: "Calendar",
                route: "/calendar"
              },
              {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-calendar-times',
                  name: "Change of Work Schedule",
                  route: "/change-schedule"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-history',
                  name: "Half Day",
                  route: "/halfday"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-plane',
                  name: "Official Business Trip",
                  route: "/business-trip"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-stopwatch',
                  name: "Overtime",
                  route: "/overtime"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-history',
                  name: "Undertime",
                  route: "/undertime"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-laptop-house',
                  name: "Work From Home",
                  route: "/wfh"
                },
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: "fa-table",
                  name: "Form 2316",
                  route: "/form-2316",
                },
              ]
              },

              "training": {
              textColor: "colorEmployee",
              activeColor: "activeColorEmployee",
              iconClass: "fa-graduation-cap",
              name: "Training",
              route: "/sms-blast",
              sub: [
                {
                  textColor: 'colorEmployee',
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-book-open',
                  name: "E-Learning",
                  route: "/lessons"
                },
              ]
              },
            
              "leaves": {
              textColor: "colorEmployee",
              activeColor: "activeColorEmployee",
              iconClass: "fa-person-walking-arrow-right",
              name: "Leaves",
              route: "/",
              sub: [
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-copy',
                  name: "My Applications",
                  route: "/my-leave-applications"
                }, 
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-file-alt',
                  name: "Application Form",
                  route: "/application-form"
                }, 
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-list-ol',
                  name: "My Leave Credits",
                  route: "/my-leave-credits"
                },
                //{
                  //textColor: "colorEmployee",
                  //activeColor: "activeColorEmployee",
                  //iconClass: 'fa-file-contract',
                  //name: "Remaining Leaves",
                  //route: "/remaining-leaves"
                //},
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-history',
                  name: "My Leave History",
                  route: "/employee-leave-history"
                },
              ]
              },

              "government-reports": {
                textColor: "colorEmployee",
                activeColor: "activeColorEmployee",
                iconClass: "fa-code",
                name: "Government Reports",
                route: "/",
                sub: [
                  // {
                  //   textColor: "colorEmployee",
                  //   activeColor: "activeColorEmployee",
                  //   iconClass: 'fa-code',
                  //   name: "Backend Dashboard",
                  //   route: "/backend-list"
                  // },
                  // {
                  //   textColor: "colorEmployee",
                  //   activeColor: "activeColorEmployee",
                  //   iconClass: 'fa-list-alt',
                  //   name: "Vertical Detailed",
                  //   route: "/vertical-summary"
                  // },
                    // {
                    //   textColor: "colorEmployee",
                    //   activeColor: "activeColorEmployee",
                    //   iconClass: 'fa-newspaper',
                    //   name: "Detailed Payslip",
                    //   route: "/detailed-payslip"
                    // },
                  {
                    textColor: "colorEmployee",
                    activeColor: "activeColorEmployee",
                    iconClass: 'fa-list-alt',
                    name: "SSS Employee Contribution",
                    route: "/sss-employee-contribution"
                  },
                  {
                    textColor: "colorEmployee",
                    activeColor: "activeColorEmployee",
                    iconClass: 'fa-list-alt',
                    name: "Philhealth Employee Contribution",
                    route: "/philhealth-employee-contribution"
                  },
                  {
                    textColor: "colorEmployee",
                    activeColor: "activeColorEmployee",
                    iconClass: 'fa-list-alt',
                    name: "Pag-ibig Employee Contribution",
                    route: "/pagibig-employee-contribution"
                  },
                  {
                    textColor: "colorEmployee",
                    activeColor: "activeColorEmployee",
                    iconClass: 'fa-list-alt',
                    name: "Alphalist",
                    route: "/alphalist"
                  },
                  {
                    textColor: "colorEmployee",
                    activeColor: "activeColorEmployee",
                    iconClass: 'fa-list-alt',
                    name: "Total Gov Contribution",
                    route: "/total-gov-contribution"
                  },
                ]
              },

              "announcement": {
              textColor: "colorEmployee",
              activeColor: "activeColorEmployee",
              iconClass: "fa-bullhorn",
              name: "Announcement",
              route: "/",
              sub: [
                {
                  textColor: "colorEmployee",
                  activeColor: "activeColorEmployee",
                  iconClass: 'fa-bullhorn',
                  name: "List of Announcements",
                  route: "/announcement-list"
                }
              ]
              }
          };
            
          for (let i in mods) {
            this.navigations.push(mods[i]);
          }
        }
      }
    },
    
    updateTime() {
      const date = new Date();
      this.currentTime = date.toLocaleTimeString('en-US', {timeZone: this.timeZone});
    },

    async getTimeZone() {
      try {
        axios.create({
            baseURL: this.apiUrl,
            headers: {
              'Authorization': `Bearer ${this.token}`
            }})
            .get('/current-time')
            .then((res) => { this.timeZone = res.data.time_zone;  
              // console.log('response:' + res.data.current_time)
            })
      } catch (error) {
        console.error('Error fetching time:', error);
        this.currentTime = 'Error fetching time'; // Handle error scenario
      }
    }
  },

  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.timeInterval);
  }
};
</script>
<style scoped>
.qExpandItem {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.module-header {
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.menu-header {
  color: #FFF;
  text-shadow: -1px -1px #888,
    1px -1px 0 #888,
    -1px 1px 0 #888,
    1px 1px 0 #888;
}

.navTitle {
  color: black;
}

.colorEmployee {
  color: black;
  font-weight: 300;
}

.activeColorEmployee {
  color: #1E88E5;
  background-color: white;
  text-shadow: none;
}

.activeColorEmployee::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
  font-weight: bold;
}

.colorPayroll {
  color: #2E7D32;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorPayroll {
  color: white;
  background-color: #2E7D32;
  text-shadow: none;
}

.activeColorPayroll::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorTimeKeep {
  color: #546E7A;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorTimeKeep {
  color: white;
  background-color: #546E7A;
  text-shadow: none;
}

.activeColorTimeKeep::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorOnlineForms {
  color: #E53935;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorOnlineForms {
  color: white;
  background-color: #E53935;
  text-shadow: none;
}

.activeColorOnlineForms::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorSettings {
  color: #212121;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorSettings {
  color: white;
  background-color: #212121;
  text-shadow: none;
}

.activeColorSettings::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorRecruitment {
  color: #3F51B5;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorRecruitment {
  color: white;
  background-color: #3F51B5;
  text-shadow: none;
}

.activeColorRecruitment::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorAnnouncement {
  color: #E65100;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorAnnouncement {
  color: white;
  background-color: #E65100;
  text-shadow: none;
}

.activeColorAnnouncement::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorBackend {
  color: #827717;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorBackend {
  color: white;
  background-color: #827717;
  text-shadow: none;
}

.activeColorBackend::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorSettings {
  color: #d50000;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorSettings {
  color: white;
  background-color: #d50000;
  text-shadow: none;
}

.activeColorSettings::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorPantry {
  color: #c51162;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorPantry {
  color: white;
  background-color: #c51162;
  text-shadow: none;
}

.activeColorPantry::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.q-bt {
  background-color: "black";
}

.sidebg {
  /* background: url('../assets/bgcrop-2.png') !important; */
  /* background-size: cover !important;
  background-repeat: no-repeat !important; */
  /*background-attachment: fixed;*/
  opacity: 1;
  top: 270px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}

/*START:: FOR NOTIFICATION*/
.notif-title {
  margin-left: 15px;
  margin-top: 15px;
}

.item-list {
  margin: 0;
  font-size: 18px;
  line-height: 1.2em;
}

.item-dt {
  line-height: 2em;
  margin-bottom: 0;
}

/*END:: FOR NOTIFICATION*/
</style>