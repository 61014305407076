<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row ">
      <div class="col-12 q-mb-md">
        <h4 class="q-ma-none">Set Employee Permissions</h4>
      </div>
      <div class="col-12 q-mb-md">
        <q-form ref="vForm" @submit="onSubmitSearch" class="q-gutter-sm">
          <q-input v-model="searchModel" dense outlined :rules="[formRules.required]" placeholder="Search employee here...">
            <template v-slot:append>
              <q-icon name="fa fa-search" />
            </template>
          </q-input>
        </q-form>
      </div>
      <div class="col-12 q-mb-md">
        <q-table title="Search Result" :data="searchResultData" :columns="searchResultColumns" row-key="employee_id" :hide-bottom="searchResultData.length > 0" :loading="loading">
          <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click="searchRowClick(props.row.name, props.row.employee_id)">
            <q-td style="display: none;" key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
            <q-td key="employee_id" :props="props">{{ props.row.company_id }}</q-td>
            <q-td key="name" :props="props">{{ props.row.name }}</q-td>
            <q-td key="position" :props="props">{{ props.row.position }}</q-td>
            <q-td key="department" :props="props">{{ props.row.department }}</q-td>
          </q-tr>
          <template v-slot:no-data="{ message }">
            <div class="full-width row flex-center text-red-5 q-gutter-sm">
              <span>
                {{ message }}
              </span>
              <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
            </div>
          </template>
        </q-table>
      </div>
      <div class="col-12" v-if="modulesArea">
        <q-card bordered class="my-card q-pa-sm">
          <div class="col-12 q-mb-md">
            <q-banner bordered dense rounded class="bg-light-blue-9 text-white text-center">
              Module Permissions For: <b>{{empName}}</b>
            </q-banner>
          </div>
          <div class="row q-col-gutter-sm">
            <div class="col-3" v-for="mods in modules" :key="mods.id">
              <q-card bordered>
                <q-card-section>
                  <div>
                    <q-item tag="label">
                      <q-item-section side top>
                        <q-toggle v-model="mods.enabled" @input="setVal(mods)" checked-icon="fa fa-check" color="green" unchecked-icon="fa fa-times" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label class="qitemLabel">{{ humanize(mods.name) }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" transition-show="scale" transition-hide="scale" :content-class="mods.enabled ? 'bg-red' : 'bg-green'" content-style="font-size: 13px">
                      <div v-if="mods.enabled === true">
                        Click to disable module.
                      </div>
                      <div v-else>
                        Click to enable module.
                      </div>
                    </q-tooltip>
                  </div>
                  <div v-if="mods.sub_modules.length > 0">
                    <q-expansion-item caption="Sub-menus">
                      <q-card-section>
                        <div v-for="submods in mods.sub_modules" :key="submods.id">
                          <div>
                            <q-item tag="label">
                              <q-item-section side top>
                                <q-toggle v-model="submods.enabled" @input="setVal(submods)" checked-icon="fa fa-check" color="green" unchecked-icon="fa fa-times" />
                              </q-item-section>
                              <q-item-section>
                                <q-item-label class="qitemLabel">{{ humanize(submods.name) }}</q-item-label>
                              </q-item-section>
                            </q-item>
                            <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" transition-show="scale" transition-hide="scale" :content-class="submods.enabled ? 'bg-red' : 'bg-green'" content-style="font-size: 13px">
                              <div v-if="submods.enabled === true">
                                Click to disable module.
                              </div>
                              <div v-else>
                                Click to enable module.
                              </div>
                            </q-tooltip>
                          </div>
                        </div>
                      </q-card-section>
                    </q-expansion-item>
                  </div>
                  <div v-else>
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </div>
          <div class="col-12" v-if="confirmConfig">
            <div class="row q-col-gutter-sm q-mt-md justify-end">
              <div class="col-3">
                <q-btn :loading="modEffectLoading" @click="moduleStatus" size="lg" style="width: 100%" push color="primary"> CONFIRM
                  <template v-slot:loading>
                    <q-spinner-gears class="on-left" />
                    UPDATING...
                  </template>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-lg">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Fetching Records
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';

const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

export default {
  name: 'ModulePermissions',
  data() {
    return {
      empName: '',
      loadingModel: false,
      loading: false,
      modEffectLoading: false,
      modulesArea: false,
      confirmConfig: false,
      searchModel: null,
      searchResultData: [],
      searchResultColumns: [{
          name: 'employee_id',
          field: 'employee_id',
          label: 'Employee ID',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px'
        },
        {
          name: 'name',
          field: 'name',
          label: 'Name',
          style: 'width: 300px; padding-left: 100px',
          align: 'left',
          headerStyle: 'width: 300px; text-align:center'
        },
        {
          name: 'position',
          field: 'position',
          label: 'Position',
          style: 'width: 300px; padding-left: 100px',
          align: 'left',
          headerStyle: 'width: 300px; text-align:center'
        },
        {
          name: 'department',
          field: 'department',
          label: 'Department',
          align: 'center',
        }
      ],
      modules: [
        {
    "name": "Timekeeping and Forms",
    "enabled": true,
    "parent_module_id": 0,
    "iconClass": "fa-clock",
    "sub_modules": [
      {
        "name": "Attendance",
        "enabled": true,
        "parent_module_id": 0,
        "route": "/attendance",
        "iconClass": "fa-user"
      },
      {
        "name": "Calendar",
        "enabled": true,
        "parent_module_id": 0,
        "route": "/calendar",
        "iconClass": "fa-calendar"
      },
      {
        "name": "Change of Work Schedule",
        "enabled": true,
        "parent_module_id": 0,
        "route": "/change-schedule",
        "iconClass": "fa-calendar-times"
      },
      {
        "name": "Half Day",
        "enabled": true,
        "parent_module_id": 0,
        "route": "/halfday",
        "iconClass": "fa-history"
      },
      {
        "name": "Official Business Trip",
        "enabled": true,
        "parent_module_id": 0,
        "route": "/business-trip",
        "iconClass": "fa-plane"
      },
      {
        "name": "Overtime",
        "enabled": true,
        "parent_module_id": 0,
        "route": "/overtime",
        "iconClass": "fa-stopwatch"
      },
      {
        "name": "Undertime",
        "enabled": true,
        "parent_module_id": 0,
        "route": "/undertime",
        "iconClass": "fa-history"
      },
      {
        "name": "Work From Home",
        "enabled": true,
        "parent_module_id": 0,
        "route": "/wfh",
        "iconClass": "fa-laptop-house"
      },
      {
        "name": "Form 2316",
        "enabled": true,
        "parent_module_id": 0,
        "route": "/form-2316",
        "iconClass": "fa-table"
      }
    ]
        },

        {
          "name": "Training",
          "enabled": true,
          "parent_module_id": 0,
          "iconClass": "fa-graduation-cap",
          "sub_modules": [
            {
              "name": "E-Learning",
              "enabled": true,
              "parent_module_id": 0,
              "route": "/lessons",
              "iconClass": "fa-book-open"
            }
          ]
        },

        {
          "name": "Leaves",
          "enabled": true,
          "parent_module_id": 0,
          "iconClass": "fa-person-walking-arrow-right",
          "sub_modules": [
            {
              "name": "My Applications",
              "enabled": true,
              "parent_module_id": 0,
              "route": "/my-leave-applications",
              "iconClass": "fa-copy"
            },
            {
              "name": "Application Form",
              "enabled": true,
              "parent_module_id": 0,
              "route": "/application-form",
              "iconClass": "fa-file-alt"
            },
            {
              "name": "My Leave Credits",
              "enabled": true,
              "parent_module_id": 0,
              "route": "/my-leave-credits",
              "iconClass": "fa-list-ol"
            },
            {
              "name": "My Leave History",
              "enabled": true,
              "parent_module_id": 0,
              "route": "/employee-leave-history",
              "iconClass": "fa-history"
            }
          ]
        },

        {
          "name": "Government Reports",
          "enabled": true,
          "parent_module_id": 0,
          "iconClass": "fa-code",
          "sub_modules": [
            {
              "name": "SSS Employee Contribution",
              "enabled": true,
              "parent_module_id": 0,
              "route": "/sss-employee-contribution",
              "iconClass": "fa-list-alt"
            },
            {
              "name": "Philhealth Employee Contribution",
              "enabled": true,
              "parent_module_id": 0,
              "route": "/philhealth-employee-contribution",
              "iconClass": "fa-list-alt"
            },
            {
              "name": "Pag-ibig Employee Contribution",
              "enabled": true,
              "parent_module_id": 0,
              "route": "/pagibig-employee-contribution",
              "iconClass": "fa-list-alt"
            },
            {
              "name": "Alphalist",
              "enabled": true,
              "parent_module_id": 0,
              "route": "/alphalist",
              "iconClass": "fa-list-alt"
            },
            {
              "name": "Total Gov Contribution",
              "enabled": true,
              "parent_module_id": 0,
              "route": "/total-gov-contribution",
              "iconClass": "fa-list-alt"
            }
          ]
        },

        {
          "name": "Announcement",
          "enabled": true,
          "parent_module_id": 0,
          "iconClass": "fa-bullhorn",
          "sub_modules": [
            {
              "name": "List of Announcements",
              "enabled": true,
              "parent_module_id": 0,
              "route": "/announcement-list",
              "iconClass": "fa-bullhorn"
            }
          ]
        },
      ],
      updatedMods: [],
      selectedIsAdmin: false,
      selectedEmployeeId: 0,
      borderRadius: 'border-radius'
    }
  },
  
  methods: {
    onSubmitSearch: function() {
      this.loading = true;
      this.modulesArea = false;
      this.$refs.vForm.validate();

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/employees/search', {
          params: {
            keyword: this.searchModel
          }
        })
        .then((res) => {
          this.loading = false;
          this.searchResultData = [];
          if (res.data.data.length > 0) {
            for (let emp of res.data.data) {
              this.searchResultData.push({
                id: emp.id,
                name: this.toTitleCase(emp.name),
                employee_id: emp.employee_id,
                company_id: emp.company_id,
                position: emp.position,
                department: emp.department
              });
            }
          } else {
            this.loadingModel = false;
            this.modulesArea = false;
            this.confirmConfig = false;
          }
        })
    },

    searchRowClick: function(rowName, rowID) {
      this.empName = rowName;
      this.selectedEmployeeId = rowID;
      this.setAllEnabled();
      this.loadingModel = true;

      let formData = new FormData();
      formData.append("id", this.selectedEmployeeId);
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .post(`/employees/get/modules`)
        .then((res) => {
          this.loadingModel = false;
          this.modulesArea = true;
          this.confirmConfig = true;
          // this.modules = res.data;
          // if(res.data.rawData){
          //   this.modules = res.data.rawData;
          // }
        });
    },

    setVal: function(value) {
      var i, v, a;
      console.log('value: ' + JSON.stringify(value));
      if (value.name == "admin") {
        for (i = 0; i < this.modules.length; i++) {
          if (value.enabled == false) {
            this.modules[i].enabled = false;
            if (this.modules[i].sub_modules.length > 0) {
              for (v = 0; v < this.modules[i].sub_modules.length; v++) {
                this.modules[i].sub_modules[v].enabled = false;
              }
            }
          } else {
            this.modules[i].enabled = true;
            if (this.modules[i].sub_modules.length > 0) {
              for (v = 0; v < this.modules[i].sub_modules.length; v++) {
                this.modules[i].sub_modules[v].enabled = true;
              }
            }
          }
        }
      } else if (value.name != "admin" && this.selectedIsAdmin == true) {
        this.modules[0].enabled = false;
        for (i = 1; i < this.modules.length; i++) {
          if (this.modules[i].enabled == false && this.modules[i].sub_modules.length > 0) {
            for (v = 0; v < this.modules[i].sub_modules.length; v++) {
              this.modules[i].sub_modules[v].enabled = false;
            }
          }
        }
      } else {
        for (i = 1; i < this.modules.length; i++) {
          if (this.modules[i].enabled == false && this.modules[i].sub_modules.length > 0) {
            for (v = 0; v < this.modules[i].sub_modules.length; v++) {
              this.modules[i].sub_modules[v].enabled = false;
            }
          }
        }
      }
    },

    moduleStatus: function() {
      console.log('rawData: ' + JSON.stringify(this.modules)) 
      this.modEffectLoading = true;
      this.updatedMods = [];
      for (let updateMods of this.modules) {
        let subMods = [];
        if (updateMods.sub_modules) {
          for (let updateSubMods of updateMods.sub_modules) {
            subMods.push({
              name: updateSubMods.name,
              iconClass: updateSubMods.iconClass,
              route: updateSubMods.route,
              enabled: updateSubMods.enabled
            });
          }
        }

        this.updatedMods.push({
          name: updateMods.name,
          enabled: updateMods.enabled,
          sub: subMods.length > 0 ? subMods : undefined // Only add 'sub' if it has elements
        });
      }

      let formData = new FormData();

      formData.append("employee_id", this.selectedEmployeeId);
      formData.append("modules", JSON.stringify(this.updatedMods));
      formData.append("raw_module_data", JSON.stringify(this.modules));

      console.log('sentModules: ' + JSON.stringify(this.updatedMods));

      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/employees/change/module', formData).then((res) => {
        console.log('Modules Set Success');
      })

      setTimeout(() => {
        this.modEffectLoading = false
        this.$q.notify({
          color: 'blue-9',
          textColor: 'white',
          icon: 'fa fa-cogs',
          message: 'Modules Set Success'
        });
        scrollToTop();
      }, 1500);
    },

    setAllEnabled: function() {
      for (let mod of this.modules) {
        mod.enabled = true;
        for (let sub of mod.sub_modules) {
          sub.enabled = true;
        }
      }
    }
  }
}
</script>
<style lang="css" scoped>
.qitemLabel {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.semi-round {
  border-radius: 10px;
}
</style>